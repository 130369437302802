import { Box, Button as MuiButton, styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";

import { Typography } from "components/Typography";
import { BackArrowIcon } from "components/Icons";
import { BusinessDetailsTable } from "./BusinessDetailsTable";
import BusinessInfo from "../BusinessTransactionWithdrawalDetails/components/BusinessInfo";
import { useGetBusinessesQuery } from "api/business";

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.sidebar,
  display: "flex",
  color: "black",
  marginTop: "25px",
  marginBottom: "25px",
  "&:hover": { backgroundColor: "#fafafa" },
}));

export const BusinessTransactions = () => {
  const navigate = useNavigate();
  const { businessId } = useParams();

  const { data: businesses } = useGetBusinessesQuery(businessId);

  const business = businesses?.find(
    (business) => business.businessId === businessId
  );
  const businessName = business?.name;

  return (
    <Box>
      {businessName && (
        <>
          <Typography variant="loginHeading">{businessName}</Typography>
        </>
      )}
      <Button
        variant="contained"
        disableRipple
        disableElevation
        onClick={() => navigate(-1)}
      >
        <BackArrowIcon />
        Back
      </Button>
      <BusinessInfo business={business} />
      <Typography variant="dashboardHeading">Business Transactions</Typography>
      <BusinessDetailsTable />
    </Box>
  );
};
