import SvgIcon from "@mui/material/SvgIcon";

export const NavMenuArrowRightIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 13 13" {...props}>
      <path
        d="M4.82677 11.1958C4.72385 11.1958 4.62094 11.1579 4.53969 11.0767C4.3826 10.9196 4.3826 10.6596 4.53969 10.5025L8.07135 6.97083C8.33135 6.71083 8.33135 6.28833 8.07135 6.02833L4.53969 2.49667C4.3826 2.33958 4.3826 2.07958 4.53969 1.9225C4.69677 1.76542 4.95677 1.76542 5.11385 1.9225L8.64552 5.45417C8.92177 5.73042 9.07885 6.10417 9.07885 6.49958C9.07885 6.895 8.92719 7.26875 8.64552 7.545L5.11385 11.0767C5.0326 11.1525 4.92969 11.1958 4.82677 11.1958Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
