import * as React from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import {
  useGridApiContext,
  useGridSelector,
  gridPaginationModelSelector,
  gridPageCountSelector,
} from "@mui/x-data-grid";

import { Typography } from "components/Typography";

export function Pagination() {
  const apiRef = useGridApiContext();
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const currentPage = paginationModel.page;
  const pageSize = paginationModel.pageSize;
  const isPrevButtonDisabled = !Boolean(currentPage);
  const isNextBtnDisabled = currentPage === pageCount - 1;

  const handlePreviousPage = () => {
    apiRef.current.setPage(currentPage - 1);
  };

  const handleNextPage = () => {
    apiRef.current.setPage(currentPage + 1);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%", padding: "30px 10px 10px" }}
    >
      <Typography variant="loginfooter" sx={{ fontSize: 14 }}>
        {`${currentPage * pageSize + 1} - ${(currentPage + 1) * pageSize} of ${
          pageCount * pageSize
        }`}
      </Typography>
      <Box display="flex" alignItems="center" columnGap="10px">
        <ButtonBase
          disableRipple
          onClick={handlePreviousPage}
          disabled={isPrevButtonDisabled}
          sx={{ color: isPrevButtonDisabled ? "#C9CFD4" : "inherit" }}
        >
          <Typography variant="loginfooter" sx={{ fontSize: 14 }}>
            Prev
          </Typography>
        </ButtonBase>
        <Box
          sx={{
            background: "#F2F4F4",
            borderRadius: "6px",
            padding: "8px 20px",
          }}
        >
          <Typography>Page {currentPage + 1}</Typography>
        </Box>
        <ButtonBase
          disableRipple
          onClick={handleNextPage}
          disabled={isNextBtnDisabled}
          sx={{ color: isNextBtnDisabled ? "#C9CFD4" : "inherit" }}
        >
          <Typography variant="loginfooter" sx={{ fontSize: 14 }}>
            Next
          </Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
}
