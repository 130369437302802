import { Box, Button as MuiButton, styled, Box as MuiBox } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { BackArrowIcon, ExportIcon } from "components/Icons";
import { Typography } from "components/Typography";
import { TransactionDetailsTable } from "./components/TransactionDetailsTable";
import { useGetTransactionDetailsQuery } from "api/transactions";

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.sidebar,
  color: "black",
  marginTop: "25px",
  marginBottom: "25px",
  "&:hover": { backgroundColor: "#fafafa" },
}));

const Wrapper = styled(MuiBox)(() => ({
  display: "flex",
  alignItems: "center",
}));

const CsvButton = styled(MuiButton)(() => ({
  borderColor: "#EFF1F4",
  color: "black",
  height: "40px",
  textTransform: "capitalize",
  ".MuiButtonBase-root-MuiButton-root": {
    borderColor: "#EFF1F4 !important",
  },
}));

export const TransactionDetails = () => {
  const params = useParams();
  const transactionId = params.id;

  const { data = [] } = useGetTransactionDetailsQuery(transactionId);
  const navigate = useNavigate();
  const totalAmount = data.reduce((acc, obj) => obj.amount + acc, 0);
  return (
    <Box>
      <Typography variant="dashboardHeading">{data[0]?.transId}</Typography>
      <Wrapper sx={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          disableRipple
          disableElevation
          onClick={() => navigate(-1)}
        >
          <BackArrowIcon />
          Back
        </Button>
        <CsvButton variant="outlined">
          <Wrapper>
            <ExportIcon />
            <div style={{ marginTop: 5 }}> Export csv</div>
          </Wrapper>
        </CsvButton>
      </Wrapper>
      <Typography variant="dashboardHeading">
        Total Amount: {totalAmount}
      </Typography>
      <TransactionDetailsTable data={data} />
    </Box>
  );
};
