import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import {
  CustomTableRow,
  EXPAND_DETAILS_COLUMN,
} from "./components/CustomTableRow";

export { EXPAND_DETAILS_COLUMN };
export const CustomTable = ({
  columns,
  rows,
  expandableRowComponent,
  getRowId,
  ...rest
}) => {
  return (
    <Table {...rest}>
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              key={headCell.field}
              align={headCell.alignRight ? "right" : "left"}
            >
              {headCell.headerName}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => {
          return (
            <CustomTableRow
              key={(getRowId?.(row) || row.id) + index}
              columns={columns}
              row={row}
              expandableRowComponent={expandableRowComponent}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
