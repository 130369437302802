import { Button as MuiButton, styled, Modal, Box } from "@mui/material";

import { LogoutIcon } from "components/Icons";
import { Typography } from "components/Typography";
import { useAuthContext } from "context/AuthContext";

const LogoutContainer = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 330,
  backgroundColor: "white",
  padding: "30px",
  borderRadius: "10px",
  alignItems: "center",
}));

const LogoutIconContainer = styled(Box)(() => ({
  color: "white",
  backgroundColor: "#D24339",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "35px",
  height: "35px",
  borderRadius: "50%",
  padding: "10px",
  margin: "20px 0px",
}));

const Button = styled(MuiButton)(() => ({
  textTransform: "capitalize",
  fontWeight: "400",
  boxShadow: "none",
}));

export const Logout = ({ open, closeLogout }) => {
  const ctx = useAuthContext();
  return (
    <>
      <Modal
        open={open}
        onClose={closeLogout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LogoutContainer align="center">
          <LogoutIconContainer>
            <LogoutIcon />
          </LogoutIconContainer>
          <Typography
            variant="emailHeading"
            id="modal-modal-title"
            component="h2"
            sx={{ fontSize: "20px", marginBottom: "15px" }}
          >
            Log Out
          </Typography>
          <Typography variant="body">
            Are you sure you want to log out?
          </Typography>
          <Button
            variant="contained"
            fullWidth
            disableRipple
            sx={{
              my: "20px",
              backgroundColor: "#D24339",
              marginTop: "40px",
              "&:hover": {
                backgroundColor: "#D24339",
                boxShadow: "none",
              },
            }}
            onClick={() => ctx.logout()}
          >
            Yes, Log out
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disableRipple
            onClick={closeLogout}
            sx={{
              border: "1px solid #2D75B6",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            No, cancel
          </Button>
        </LogoutContainer>
      </Modal>
    </>
  );
};
