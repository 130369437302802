import SvgIcon from "@mui/material/SvgIcon";

export const KeyboardArrowUp = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_775_30457)">
        <path
          d="M12.0007 10.8273L16.9507 15.7773L18.3647 14.3633L12.0007 7.99934L5.63672 14.3633L7.05072 15.7773L12.0007 10.8273Z"
          fill="#394E51"
        />
      </g>
      <defs>
        <clipPath id="clip0_775_30457">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(1 0 0 -1 0 24)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
