import React from "react";
import { useState } from "react";
import { styled } from "@mui/material";
import { Button } from "@mui/material";

import { DataGrid } from "components/DataGrid";
import { TeamOption } from "./MenuOption";
import { TeamMateDetails } from "./TeamMateDetails";
import { ResendInvite } from "./ResendInvite";

const InviteButton = styled(Button)(() => ({
  background: "#E9F8F3",
  color: "#25B883",
  padding: "4px 16px",
  borderRadius: "20px",
  textTransform: "capitalize",
  display: "inline-flex",
  alignItems: "center",
}));

const generateColumns = ({ toggle }) => [
  {
    field: "fullname",
    headerName: "Full Name",
    flex: 1,
    renderCell: (grid) => {
      if (grid.row.invite === "PENDING") {
        return <InviteButton>Invite Sent</InviteButton>;
      } else {
        return grid.row.fullname;
      }
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },

  {
    field: "roles",
    headerName: "Role",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (grid) => <ResendInvite data={grid.row} />,
  },
  {
    field: "icon",
    headerName: "",
    renderCell: (grid) => (
      <TeamOption handleMenuClick={() => toggle(grid.row)} data={grid.row} />
    ),
  },
];

export function TeamDataGrid({ data, loading }) {
  const [currentRow, setCurrentRow] = useState(null);
  const [openSummaryDetails, setOpenSummaryDetails] = useState(false);

  const toggle = (row) => {
    setCurrentRow(row);
    setOpenSummaryDetails((prev) => !prev);
  };

  return (
    <>
      <DataGrid
        rows={data || []}
        loading={loading}
        columns={generateColumns({ toggle })}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        checkboxSelection
        enablePagination={false}
        disableColumnMenu={true}
        isRowSelectable={(params) => params.row.quantity > 500000}
      />

      <TeamMateDetails
        data={currentRow}
        open={openSummaryDetails}
        onClose={toggle}
      />
    </>
  );
}
