import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFetch, postFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";
import * as queryKeys from "utils/queryKeys";
import { useSnackbar } from "notistack";

const getBankRequest = async () => await getFetch(`${baseURL}/banks`);

export const useGetBankQuery = () =>
  useQuery({
    queryFn: getBankRequest,
    queryKey: [queryKeys.Banks],
    select: (data) => data?.data.banks,
  });

const getBankDetailsRequest = async () =>
  await getFetch(`${baseURL}/bank-account`);

export const useGetBankDetailsQuery = () =>
  useQuery({
    queryFn: () => getBankDetailsRequest(),
    queryKey: [queryKeys.BankAccounts],
    select: (data) => data?.data.bankAccounts,
  });

const createAccountRequest = async (data) =>
  await postFetch(`${baseURL}/bank-acount`, "POST", {}, data);

export const useCreateAccountMutation = (options) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: createAccountRequest,
    onSuccess: () => {
      query.invalidateQueries({ queryKey: [queryKeys.BankAccounts] });
    },
    ...options,
  });
};

const createDefaultAccountRequest = async (data) =>
  await postFetch(
    `${baseURL}/bank-account/default/${data?.bankAccountId}`,
    "POST",
    {},
    data
  );

export const useCreateDefaultAccountMutation = (options) => {
  const query = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: createDefaultAccountRequest,
    onSuccess: (data) => {
      if (data?.status !== "error") {
        query.invalidateQueries({ queryKey: [queryKeys.BankAccounts] });
        enqueueSnackbar("Account successfully set as default!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`${data.message}`, {
          variant: "error",
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    ...options,
  });
};
const CreateAccountNameRequest = async (data) =>
  await postFetch(`${baseURL}/bank-account/account-name`, "POST", {}, data);

export const useGetAccountNameMutation = (options) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: CreateAccountNameRequest,
    onSuccess: (data) => {
      if (data?.status === "success") {
        enqueueSnackbar(`${data.message}`, {
          variant: "success",
        });
        options?.onSuccess?.(data);
      } else {
        enqueueSnackbar(`${data?.message || "An error occurred"}`, {
          variant: "error",
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    ...options,
  });
};
