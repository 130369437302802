import { Button, Menu, MenuItem } from "@mui/material";
import { MenuIcon } from "components/Icons";
import React from "react";

const moreOptions = ["View business details", "Generate API key"];

export const BusinessOptions = ({ clicker }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (option) => {
    clicker(option);
    handleClose();
  };

  return (
    <section>
      <Button onClick={handleClick}>
        <MenuIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {moreOptions.map((option) => (
          <MenuItem
            sx={{ fontSize: "13px" }}
            key={option}
            selected={option === "Pyxis"}
            onClick={() => handleItemClick(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </section>
  );
};
