import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

import { MenuIcon } from "components/Icons";
import { SetAccountAsDefaultModal } from "./SetAccountAsDafault";

const moreOptions = ["Set as Default Account"];

export const BankAccountMenu = ({ account }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickRowOption = (option) => {
    if (option === "Set as Default Account") {
      setOpenModal(true);
    }
    handleClose();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    setOpenModal(false);
  };

  return (
    <section>
      <Button onClick={handleClick}>
        <MenuIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {moreOptions.map((option) => (
          <MenuItem
            sx={{ fontSize: "13px" }}
            key={option}
            onClick={() => handleClickRowOption(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      <SetAccountAsDefaultModal
        account={account}
        open={openModal}
        handleClose={handleModalClose}
        handleConfirm={handleConfirm}
      />
    </section>
  );
};
