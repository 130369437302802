import { Box as MuiBox, styled, Skeleton } from "@mui/material";

import { Typography } from "components/Typography";

const ContactHeading = styled(Typography)(() => ({
  color: "#7C7A78",
  fontSize: "14px",
}));

const Box = styled(MuiBox)(() => ({
  display: "flex",
  backgroundColor: "#fafafa",
  justifyContent: "space-between",
  padding: "25px",
  marginBottom: "35px",
  borderRadius: "10px",
}));

const BusinessInfo = ({ business }) => {
  if (!business) {
    return <Skeleton height={120} />;
  }

  return (
    <Box>
      <div>
        <ContactHeading>Business Address</ContactHeading>
        <Typography>{business.address}</Typography>
      </div>
      <div>
        <ContactHeading>Contact's Name</ContactHeading>
        <Typography>{business.name}</Typography>
      </div>
      <div>
        <ContactHeading>Contact's Email</ContactHeading>
        <Typography>{business.contactEmail}</Typography>
      </div>
      <div>
        <ContactHeading>Contact's Phone No.</ContactHeading>
        <Typography>{business.contactPhone}</Typography>
      </div>
    </Box>
  );
};

export default BusinessInfo;
