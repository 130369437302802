import SvgIcon from "@mui/material/SvgIcon";

export const ActivityLogIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M16.3809 22.7493H3.2409C2.2909 22.7493 1.41091 22.3093 0.840908 21.5393C0.260908 20.7593 0.0909169 19.7792 0.380917 18.8492L4.59091 5.31921C4.97091 4.05921 6.13092 3.19922 7.45092 3.19922H19.7509C20.9609 3.19922 22.0509 3.91931 22.5109 5.03931C22.7609 5.61931 22.8109 6.27932 22.6609 6.92932L19.2909 20.4592C18.9709 21.8092 17.7709 22.7493 16.3809 22.7493ZM7.4609 4.70923C6.8109 4.70923 6.22091 5.13929 6.03091 5.76929L1.82092 19.2993C1.68092 19.7693 1.76091 20.2593 2.06091 20.6593C2.34091 21.0393 2.78091 21.2593 3.25091 21.2593H16.3909C17.0809 21.2593 17.6809 20.7893 17.8409 20.1193L21.2109 6.57922C21.2909 6.24922 21.2709 5.91927 21.1409 5.62927C20.9009 5.05927 20.3709 4.69922 19.7609 4.69922H7.4609V4.70923Z"
        fill="currentColor"
      />
      <path
        d="M20.78 22.7509H16C15.59 22.7509 15.25 22.4109 15.25 22.0009C15.25 21.5909 15.59 21.2509 16 21.2509H20.78C21.19 21.2509 21.57 21.081 21.85 20.781C22.13 20.481 22.27 20.081 22.24 19.671L21.25 6.05099C21.22 5.64099 21.53 5.28094 21.94 5.25094C22.35 5.23094 22.71 5.53089 22.74 5.94089L23.73 19.561C23.79 20.381 23.5 21.201 22.94 21.801C22.39 22.411 21.6 22.7509 20.78 22.7509Z"
        fill="currentColor"
      />
      <path
        d="M9.67977 7.13069C9.61977 7.13069 9.55977 7.12067 9.49977 7.11067C9.09977 7.01067 8.84979 6.61064 8.94979 6.20064L9.98976 1.88069C10.0898 1.48069 10.4898 1.23064 10.8998 1.33064C11.2998 1.43064 11.5498 1.83068 11.4498 2.24068L10.4098 6.56074C10.3298 6.90074 10.0198 7.13069 9.67977 7.13069Z"
        fill="currentColor"
      />
      <path
        d="M16.3795 7.13974C16.3295 7.13974 16.2694 7.13972 16.2194 7.11972C15.8194 7.02972 15.5595 6.62971 15.6395 6.22971L16.5794 1.88974C16.6694 1.47974 17.0694 1.22979 17.4694 1.30979C17.8694 1.38979 18.1294 1.79968 18.0494 2.19968L17.1094 6.53977C17.0394 6.89977 16.7295 7.13974 16.3795 7.13974Z"
        fill="currentColor"
      />
      <path
        d="M15.6992 12.75H7.69922C7.28922 12.75 6.94922 12.41 6.94922 12C6.94922 11.59 7.28922 11.25 7.69922 11.25H15.6992C16.1092 11.25 16.4492 11.59 16.4492 12C16.4492 12.41 16.1092 12.75 15.6992 12.75Z"
        fill="currentColor"
      />
      <path
        d="M14.6992 16.75H6.69922C6.28922 16.75 5.94922 16.41 5.94922 16C5.94922 15.59 6.28922 15.25 6.69922 15.25H14.6992C15.1092 15.25 15.4492 15.59 15.4492 16C15.4492 16.41 15.1092 16.75 14.6992 16.75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
