import logo from "assets/logo.png";
import { useAuthContext } from "context/AuthContext";
import { Navigate, Outlet } from "react-router-dom";

export const AuthLayout = () => {
  const { isLoggedIn } = useAuthContext();

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <img src={logo} alt="logo" />
      <Outlet />
    </>
  );
};
