import {
  Box,
  Button,
  styled,
  Select as MuiSelect,
  MenuItem,
  Box as MuiBox,
  FormControl,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import { Modal } from "components/Modal";
import { Typography } from "components/Typography";
import { TextInput } from "components/TextInput";
import { useInviteTeamMutation } from "api/teammates";
import { formatRequestPayload } from "utils/helpers";
import { useGetBusinessesQuery } from "api/business";
import { useGetRoles } from "api/roles";

const Wrapper = styled(MuiBox)(() => ({
  display: "flex",
  alignItems: "center",
}));

const Title = styled("h2")(() => ({
  fontSize: "20px",
  fontFamily: "BR Firma",
  fontWeight: 600,
  textAlign: "center",
}));

const TextWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 0px",
}));

const Span = styled("span")(() => ({
  color: "#2D75B6",
  textDecoration: "underline",
  cursor: "pointer",
}));

const Select = styled(MuiSelect)(({ theme, error }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${
      error ? theme.palette.error.main : "#EFF1F4"
    } !important`,
  },
}));
   export const InviteTeamMate = ({ isPending }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: inviteTeam } = useInviteTeamMutation();
  const { data } = useGetBusinessesQuery();
  const { data: rolesData } = useGetRoles();

  const [selectLabel] = useState(true);
  const [selectedrole, setSelectedRole] = useState("");
  const [selectedbusiness, setSelectedBusiness] = useState("");
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/administration/role-privileges", {
      state: { createRole: true },
    });
  };

  const handleChange1 = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleChange2 = (event) => {
    setSelectedBusiness(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      roleId: "",
      businessId: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
      roleId: Yup.string().required(),
      businessId: Yup.string().required(),
    }),
    onSubmit: (values) => {
      inviteTeam(formatRequestPayload(values), {
        onSuccess: () => {
          enqueueSnackbar("team mate invited", {
            variant: "success",
          });
          closeModal();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      });
    },
  });

  const opneModal = () => setModal(true);

  function closeModal() {
    setModal(false);
    formik.resetForm();
  }

  return (
    <>
      <Wrapper>
        <Button
          disableRipple
          sx={{
            textTransform: "capitalize",
            padding: "12px 24px",
            boxShadow: "none",
            fontWeight: "400",
            "&:hover": { backgroundColor: "#2D75B6", boxShadow: "none" },
          }}
          variant="contained"
          onClick={opneModal}
        >
          Invite team mate
        </Button>

        <Modal open={modal} handleClose={closeModal}>
          {selectLabel && (
            <div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <FormControl sx={{ width: "100%", paddingBottom: "10px" }}>
                  <Title>Invite Team Mate</Title>
                  <TextInput
                    label="Email Address"
                    type="text"
                    placeholder="email@example.com, email2@example.com..."
                    {...formik.getFieldProps("email")}
                    error={
                      Boolean(formik.touched.email) &&
                      Boolean(formik.errors.email)
                    }
                  />
                </FormControl>
                <div>
                  <FormControl sx={{ width: "100%", py: "10px" }}>
                    <TextWrapper>
                      <Typography variant="loginLabel">Role </Typography>
                      <Span onClick={handleNavigate}>Create new role </Span>
                    </TextWrapper>
                    <Select
                      labelId="select-label-1"
                      id="select-1"
                      value={selectedrole}
                      size="small"
                      onChange={handleChange1}
                      {...formik.getFieldProps("roleId")}
                      error={
                        Boolean(formik.touched.roleId) &&
                        Boolean(formik.errors.roleId)
                      }
                    >
                      {rolesData?.map((role) => (
                        <MenuItem key={role.id} value={role.roleId}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: "100%", py: "10px" }}>
                    <Typography
                      variant="loginLabel"
                      sx={{ padding: "10px 0px" }}
                    >
                      Assign to
                    </Typography>
                    <Select
                      labelId="select-label-2"
                      id="select-2"
                      size="small"
                      value={selectedbusiness}
                      onChange={handleChange2}
                      {...formik.getFieldProps("businessId")}
                      error={
                        Boolean(formik.touched.businessId) &&
                        Boolean(formik.errors.businessId)
                      }
                    >
                      {data?.map((business) => (
                        <MenuItem key={business.id} value={business.businessId}>
                          {business.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    disableRipple
                    type="submit"
                    variant="contained"
                    disabled={isPending}
                    sx={{
                      width: "100%",
                      margin: "25px 0px",
                      textTransform: "capitalize",
                    }}
                    size="large"
                  >
                    Send Invite
                    {isPending && (
                      <CircularProgress size={18} sx={{ ml: 0.5 }} />
                    )}
                  </Button>
                </div>
              </form>
            </div>
          )}
        </Modal>
      </Wrapper>
    </>
  );
};
