import { styled, Box, Button, InputBase } from "@mui/material";
import { format } from "date-fns";

import { Typography } from "components/Typography";
import { ExportIcon, SearchIcon } from "components/Icons";
import { BankAccountMenu } from "./components/BankAccountMenu";
import { AddBankAccount } from "./components/AddBankAccount";
import { DataGrid } from "components/DataGrid";
import { useGetBankDetailsQuery } from "api/bank";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const InviteButton = styled(Button)(() => ({
  background: "#E9F8F3",
  color: "#25B883",
  padding: "4px 16px",
  borderRadius: "20px",
  textTransform: "capitalize",
  display: "inline-flex",
  alignItems: "center",
}));

const FalseButton = styled(Button)(() => ({
  background: "#F8C8DC",
  color: "#f00",
  padding: "4px 16px",
  borderRadius: "20px",
  textTransform: "capitalize",
  display: "inline-flex",
  alignItems: "center",
}));

const CsvButton = styled(Button)(() => ({
  borderColor: "#EFF1F4",
  color: "black",
  height: "40px",
  textTransform: "capitalize",
  ".MuiButtonBase-root-MuiButton-root": {
    borderColor: "#EFF1F4 !important",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  border: "1.5px solid #EFF1F4",
  borderRadius: "8px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const generateTableColumns = () => [
  { field: "accountNumber", headerName: "Account Number", flex: 1 },
  { field: "accountName", headerName: "Account Name", flex: 1 },
  { field: "bankName", headerName: "Bank Name", flex: 1 },
  {
    field: "isDefault",
    headerName: "Default Account",
    flex: 1,
    renderCell: ({ row }) =>
      row.isDefault === 1 ? (
        <InviteButton>True</InviteButton>
      ) : (
        <FalseButton>False</FalseButton>
      ),
  },
  { field: "createdBy", headerName: "Created By", flex: 1 },
  {
    field: "createdOn",
    headerName: "Date Created",
    flex: 1,
    valueGetter: ({ value }) => new Date(value * 1000),
    renderCell: ({ value }) => format(value, "dd/MM/yyyy hh:mm a"),
  },
  {
    field: "more",
    headerName: "",
    align: "center",
    renderCell: ({ row }) => <BankAccountMenu account={row} />,
  },
];

export const Banks = () => {
  const { data = [], isLoading } = useGetBankDetailsQuery();

  return (
    <Box>
      <Wrapper sx={{ justifyContent: "space-between", marginBottom: 3 }}>
        <Wrapper>
          <Typography variant="dashboardHeading">Banks</Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Wrapper>

        <Wrapper>
          <AddBankAccount />
          <CsvButton variant="outlined">
            <Wrapper>
              <ExportIcon />
              <div style={{ marginTop: 5 }}> Export csv</div>
            </Wrapper>
          </CsvButton>
        </Wrapper>
      </Wrapper>

      <DataGrid
        rows={data}
        loading={isLoading}
        columns={generateTableColumns()}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[9]}
        disableColumnMenu
        disableRowSelectionOnClick
      />
    </Box>
  );
};
