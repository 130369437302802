import React from "react";
import {
  Box,
  Button,
  Typography,
  styled,
  CircularProgress,
} from "@mui/material";

import { Modal } from "components/Modal";
import { useCreateDefaultAccountMutation } from "api/bank";

const Title = styled("h2")(() => ({
  fontSize: "20px",
  fontFamily: "BR Firma",
  fontWeight: 600,
  textAlign: "center",
  paddingBottom: "10px",
}));

const Wrapper = styled(Box)(() => ({
  fontFamily: "BR Firma",
  fontSize: "16px",
}));

export const SetAccountAsDefaultModal = ({ open, handleClose, account }) => {
  const { mutate: createDefault, isPending: loading } =
    useCreateDefaultAccountMutation();

  const handleConfirmWithSnackbar = () => {
    createDefault(account, {
      onSuccess: (response) => {
        if (response?.status !== "error") {
          handleClose();
        }
      },
      onError: (error) => {},
    });
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Title>Set as Default Account</Title>

      <Wrapper>
        <Typography variant="body" lineHeight="25px">
          Are you sure you want to set this account as the default?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleClose} sx={{ mr: 1 }} disabled={loading}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmWithSnackbar}
            disabled={loading}
            endIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Confirm
          </Button>
        </Box>
      </Wrapper>
    </Modal>
  );
};
