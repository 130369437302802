import React, { useState, createContext, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import { SETTLEMENT_TOKEN_KEY } from "constants/authCred";
import { useGetProfileQuery } from "api/settings";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [userProfile, setUserProfile] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(
    Boolean(sessionStorage.getItem(SETTLEMENT_TOKEN_KEY))
  );

  const { data: profileData, error } = useGetProfileQuery({
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (profileData) {
      setUserProfile(profileData);
    }
  }, [profileData]);

  useEffect(() => {
    if (error?.statusCode === 401) {
      enqueueSnackbar("Session Expired Please Login to Continue", {
        variant: "error",
      });
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  function login(profile) {
    setUserProfile(profile);
    setLoggedIn(true);
    if (location?.state?.pathname) {
      navigate(location.state.pathname);
    }
  }

  function logout() {
    setUserProfile(null);
    setLoggedIn(false);
    sessionStorage.removeItem(SETTLEMENT_TOKEN_KEY);
    navigate("/login", { state: { pathname: location.pathname } });
  }

  const data = {
    isLoggedIn,
    userProfile,
    logout,
    login,
  };

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
