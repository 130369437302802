import React from "react";
import { Box } from "@mui/material";

import { TextInput } from "components/TextInput";
import { useAuthContext } from "context/AuthContext";

export const Profile = () => {
  const { userProfile } = useAuthContext();

  return (
    <Box sx={{ width: "500px", paddingX: "50px" }}>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flexStart",
          gap: "30px",
        }}
      >
        <TextInput
          label="Full Name"
          type="text"
          value={userProfile?.account?.fullname}
        />
        <TextInput
          label="Email Address"
          type="email"
          value={userProfile?.account?.email}
        />
        <TextInput
          label="Role"
          type="text"
          value={userProfile?.account?.createdBy}
        />
      </form>
    </Box>
  );
};
