import { SETTLEMENT_TOKEN_KEY } from "constants/authCred";

export async function getFetch(url, headers) {
  //Get the stored token from local storage;
  const token = sessionStorage.getItem(SETTLEMENT_TOKEN_KEY);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    const res = await response.json();
    const error = new Error(res.message || response.status);
    error["statusCode"] = response.status;
    throw error;
  }
  return response.json();
}

export async function postFetch(url, method = "POST", headers, data = {}) {
  //Get the stored token from local storage;
  const token = sessionStorage.getItem(SETTLEMENT_TOKEN_KEY);

  const response = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data }),
  });

  if (!response.ok) {
    const res = await response.json();
    const error = new Error(res.message || response.status);
    error["statusCode"] = response.status;
    throw error;
  }

  return response.json();
}
