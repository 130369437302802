import React from "react";
import { Box } from "@mui/material";

import { Typography } from "components/Typography";
import { SettingsTab } from "./components/SettingTabs";

export const Settings = () => {
  return (
    <Box>
      <Typography variant="dashboardHeading">Settings</Typography>
      <Box sx={{ paddingTop: "40px" }}>
        <SettingsTab />
      </Box>
    </Box>
  );
};
