import React from "react";
import { CardContent, Box, styled } from "@mui/material";
import Card from "@mui/material/Card";

import { Typography } from "components/Typography";

const WidgetBox = styled(Card)(() => ({
  borderRadius: "10px",
  border: " 1px solid #F1F0F5",
  width: "350px",
  padding: "10px",
  boxShadow: "0px 1px 12px 0px rgba(21, 41, 82, 0.03)",
}));

const BoxContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px",
  color: "#50555E",
}));
const BoxContainer = styled(Box)(() => ({
  gap: "10px",
  justifyContent: "space-between",
  display: "flex",
  paddingTop: "24px",
}));

export const CircleIcon = styled(Box)(() => ({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  border: "1px solid #D9DEDF",
}));

export const CardContainer = ({ transactionSummary }) => {
  const cardList = [
    {
      title: "Transactions Count",
      amount: transactionSummary.transactions_count,
    },
    {
      title: "Total Transactions",
      amount: transactionSummary.total_transactions,
    },
    {
      title: "Total Successful Transactions",
      amount: transactionSummary.total_successful_amount,
    },
    {
      title: "Total Failed Transactions",
      amount: transactionSummary.total_failed_amount,
    },
  ];

  return (
    <BoxContainer>
      {cardList &&
        cardList.map((item, index) => (
          <WidgetBox key={index}>
            <CardContent>
              <BoxContent>
                <Typography variant="dashboardBody" gutterBottom>
                  {item.title}
                </Typography>
                <CircleIcon />
              </BoxContent>
              <Typography variant="dashboardHeading">{item.amount}</Typography>
            </CardContent>
          </WidgetBox>
        ))}
    </BoxContainer>
  );
};
