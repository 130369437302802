import SvgIcon from "@mui/material/SvgIcon";

export const CopyIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 15 16" {...props}>
      <path
        d="M7.125 14.7188H4.75C2.00625 14.7188 0.78125 13.4937 0.78125 10.75V8.375C0.78125 5.63125 2.00625 4.40625 4.75 4.40625H6.625C6.88125 4.40625 7.09375 4.61875 7.09375 4.875C7.09375 5.13125 6.88125 5.34375 6.625 5.34375H4.75C2.5125 5.34375 1.71875 6.1375 1.71875 8.375V10.75C1.71875 12.9875 2.5125 13.7812 4.75 13.7812H7.125C9.3625 13.7812 10.1562 12.9875 10.1562 10.75V8.875C10.1562 8.61875 10.3687 8.40625 10.625 8.40625C10.8813 8.40625 11.0938 8.61875 11.0938 8.875V10.75C11.0938 13.4937 9.86875 14.7188 7.125 14.7188Z"
        fill="currentColor"
      />
      <path
        d="M10.625 9.34283H8.625C6.86875 9.34283 6.15625 8.63033 6.15625 6.87408V4.87408C6.15625 4.68658 6.26875 4.51158 6.44375 4.44283C6.61875 4.36783 6.81875 4.41158 6.95625 4.54283L10.9563 8.54283C11.0875 8.67408 11.1312 8.88033 11.0563 9.05533C10.9875 9.23033 10.8125 9.34283 10.625 9.34283ZM7.09375 6.00533V6.87408C7.09375 8.11783 7.38125 8.40533 8.625 8.40533H9.49375L7.09375 6.00533Z"
        fill="currentColor"
      />
      <path
        d="M9.75 2.21875H7.25C6.99375 2.21875 6.78125 2.00625 6.78125 1.75C6.78125 1.49375 6.99375 1.28125 7.25 1.28125H9.75C10.0062 1.28125 10.2188 1.49375 10.2188 1.75C10.2188 2.00625 10.0062 2.21875 9.75 2.21875Z"
        fill="currentColor"
      />
      <path
        d="M4.375 4.09375C4.11875 4.09375 3.90625 3.88125 3.90625 3.625C3.90625 2.33125 4.95625 1.28125 6.25 1.28125H7.8875C8.14375 1.28125 8.35625 1.49375 8.35625 1.75C8.35625 2.00625 8.14375 2.21875 7.8875 2.21875H6.25C5.475 2.21875 4.84375 2.85 4.84375 3.625C4.84375 3.88125 4.63125 4.09375 4.375 4.09375Z"
        fill="currentColor"
      />
      <path
        d="M11.9941 11.5938C11.7379 11.5938 11.5254 11.3812 11.5254 11.125C11.5254 10.8688 11.7379 10.6562 11.9941 10.6562C12.7066 10.6562 13.2816 10.075 13.2816 9.36875V5.5C13.2816 5.24375 13.4941 5.03125 13.7504 5.03125C14.0066 5.03125 14.2191 5.24375 14.2191 5.5V9.36875C14.2191 10.5938 13.2191 11.5938 11.9941 11.5938Z"
        fill="currentColor"
      />
      <path
        d="M13.75 5.96783H11.875C10.2125 5.96783 9.53125 5.28658 9.53125 3.62408V1.74908C9.53125 1.56158 9.64375 1.38658 9.81875 1.31783C9.99375 1.24283 10.1938 1.28658 10.3313 1.41783L14.0813 5.16783C14.2125 5.29908 14.2563 5.50533 14.1813 5.68033C14.1125 5.85533 13.9375 5.96783 13.75 5.96783ZM10.4688 2.88033V3.62408C10.4688 4.76783 10.7312 5.03033 11.875 5.03033H12.6188L10.4688 2.88033Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
