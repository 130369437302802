import { Box, Button, CircularProgress, Link, styled } from "@mui/material";
import { useState } from "react";

import { CopyIcon } from "components/Icons";
import { Modal } from "components/Modal";
import { ResetApi } from "./ResetApi";
import { Typography } from "components/Typography";
import { useGetBusinesseApiKeyQuery } from "api/business";

const KeyHolder = styled(Box)(() => ({
  padding: "20px 40px",
  backgroundColor: "#F9FAFA",
  border: "1px solid #EBEDEF",
  borderRadius: "5px",
}));

const ApiButton = styled(Button)(() => ({
  padding: "10px 0px",
  margin: "30px 0px",
  textTransform: "capitalize",
  "&:hover": { backgroundColor: "#2D75B6", boxShadow: "none" },
}));

export const ApiKey = ({ open, handleClose, businessId }) => {
  const { data, isPending } = useGetBusinesseApiKeyQuery(businessId);

  const [reset, setReset] = useState(false);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data.apiKey).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      },
      () => {
        console.error("error copying clipboard");
      }
    );
  };

  const openReset = () => {
    setReset(true);
    handleClose();
  };
  const closeReset = () => {
    setReset((prev) => !prev);
  };

  return (
    <>
      <Modal open={open} handleClose={handleClose}>
        <Typography
          variant="emailHeading"
          align="center"
          id="modal-modal-title"
          component="h2"
          sx={{ fontSize: "20px", my: "15px", marginBottom: "40px" }}
        >
          API Key
        </Typography>
        <KeyHolder>
          <Typography align="center" sx={{ fontSize: "13px" }}>
            {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
            {data?.apiKey}
          </Typography>
        </KeyHolder>
        <Typography align="right">
          <Link
            color="#23203D"
            href="#"
            sx={{ fontSize: "11px" }}
            onClick={openReset}
          >
            Reset API Key
          </Link>
        </Typography>
        <ApiButton
          variant="contained"
          fullWidth
          disableRipple
          onClick={copyToClipboard}
          disabled={isPending}
        >
          {copied ? (
            "Copied!"
          ) : (
            <>
              Copy API Key <CopyIcon />
            </>
          )}
        </ApiButton>
      </Modal>
      <ResetApi reset={reset} closeReset={closeReset} businessId={businessId} />
    </>
  );
};
