import { useState, useEffect } from "react";

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    clearTimeout(timeoutId);

    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    setTimeoutId(timer);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);

  return debouncedValue;
};
