import React from "react";
import { Box } from "@mui/material";

import { useGetDashboardQuery } from "api/dashboard";

import { BalanceSummaryTable } from "./BalanceSummaryTable";
import { CardContainer } from "./CardContainer";
import { DashboardLoadingSkeleton } from "./DashbaordLoadingSkeleton";

export function DashboardContent() {
  const { data, isLoading, isError } = useGetDashboardQuery();

  if (isLoading || isError) {
    return <DashboardLoadingSkeleton />;
  }

  return (
    <>
      <CardContainer transactionSummary={data?.transactionSummary} />
      <Box sx={{ paddingTop: "40px" }}>
        <BalanceSummaryTable balances={data?.balances || []} />
      </Box>
    </>
  );
}
