import { createTheme } from "@mui/material/styles";

export const fontFamily = "BR Firma, sans-serif";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2D75B6",
    },
    secondary: {
      main: "#F9FAFA;",
    },
    success: {
      main: "#25B883",
    },
    error: {
      main: "#EF4444",
    },
    background: {
      default: "#fff",
      sidebar: "#F9FAFA",
    },
  },
  typography: {
    fontFamily: fontFamily,
  },
});
