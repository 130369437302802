import { styled } from "@mui/material/styles";
import { AppBar as MuiAppBar, Toolbar, Box } from "@mui/material";

import { SIDEBAR_DRAWER_WIDTH, HEADER_HEIGHT } from "constants/layout";
import { Typography } from "components/Typography";
import { getUserInitials } from "utils/helpers";
import { useGetProfileQuery } from "api/settings";

const AppBar = styled(MuiAppBar)(() => ({
  width: `calc(100% - ${SIDEBAR_DRAWER_WIDTH}px)`,
  ml: `${SIDEBAR_DRAWER_WIDTH}px`,
  backgroundColor: "#fff",
  height: `${HEADER_HEIGHT}px`,
  borderBottomWidth: "2px",
  borderBottomStyle: "solid",
  borderBottomColor: "#F9FAFA",
  color: "black",
}));

const UserInitial = styled(Box)(() => ({
  display: "flex",
  width: "32px",
  height: "32px",
  borderRadius: "16px",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#F0E9E9",
}));

export const Header = () => {
  const { data, isLoading, isError, error } = useGetProfileQuery();
  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>{error.message}</span>;
  }
  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar sx={{ display: "flex", justifyContent: "end", paddingY: 1 }}>
        <UserInitial>
          <Typography variant="emailBody" sx={{ color: "#8B5C5D" }}>
            {getUserInitials(data?.fullname)}
          </Typography>
        </UserInitial>
        <Typography
          variant="emailSubHeadline"
          sx={{ marginLeft: 1, fontWeight: 400, color: "#213336" }}
        >
          {data?.fullname}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
