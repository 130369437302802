import SvgIcon from "@mui/material/SvgIcon";

export const DashboardIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props} className="Dashboard-icon">
      <path
        d="M8.12094 2.53906H6.21094C4.83023 2.53906 3.71094 3.64985 3.71094 5.02008V6.91557C3.71094 8.28579 4.83023 9.39658 6.21094 9.39658H8.12094C9.50165 9.39658 10.6209 8.28579 10.6209 6.91557V5.02008C10.6209 3.64985 9.50165 2.53906 8.12094 2.53906Z"
        stroke="#50555E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M8.12094 12.2949H6.21094C4.83023 12.2949 3.71094 13.4057 3.71094 14.7759V16.6714C3.71094 18.0417 4.83023 19.1524 6.21094 19.1524H8.12094C9.50165 19.1524 10.6209 18.0417 10.6209 16.6714V14.7759C10.6209 13.4057 9.50165 12.2949 8.12094 12.2949Z"
        stroke="#50555E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M17.7909 2.53906H15.8809C14.5001 2.53906 13.3809 3.64985 13.3809 5.02008V6.91557C13.3809 8.28579 14.5001 9.39658 15.8809 9.39658H17.7909C19.1716 9.39658 20.2909 8.28579 20.2909 6.91557V5.02008C20.2909 3.64985 19.1716 2.53906 17.7909 2.53906Z"
        stroke="#50555E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M17.7909 12.2949H15.8809C14.5001 12.2949 13.3809 13.4057 13.3809 14.7759V16.6714C13.3809 18.0417 14.5001 19.1524 15.8809 19.1524H17.7909C19.1716 19.1524 20.2909 18.0417 20.2909 16.6714V14.7759C20.2909 13.4057 19.1716 12.2949 17.7909 12.2949Z"
        stroke="#50555E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
