import * as React from "react";
import { styled } from "@mui/material/styles";
import { DataGrid as MuiXDataGrid } from "@mui/x-data-grid";

import { Pagination } from "./components/Pagination";
import { TableLoadingSkeleton } from "./components/TableLoadingSkeleton";

const StyledMuiXDataGrid = styled(MuiXDataGrid)(() => ({
  font: "14px",
  width: "100%",
  border: "none",

  ".MuiSvgIcon-root": {
    color: "#BFC8CA !important",
  },
  ".MuiDataGrid-withBorderColor": {
    borderColor: "#EBEEEF",
  },
  ".MuiDataGrid-cell:focus": {
    outline: "none",
  },

  ".MuiDataGrid-columnHeaderTitle": {
    textTransform: "uppercase",
    fontWeight: "600",
    borderBottom: "none",
  },
  ".MuiDataGrid-overlayWrapperInner": {
    height: "200px !important",
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    minHeight: "200px !important",
  },
}));

export function DataGrid(props) {
  return (
    <StyledMuiXDataGrid
      autoHeightß
      {...props}
      slots={{
        pagination: Pagination,
        loadingOverlay: TableLoadingSkeleton,
        ...props?.slots,
      }}
    />
  );
}
