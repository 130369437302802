import { Button, ButtonBase, CircularProgress } from "@mui/material";
import { ResendIcon } from "components/Icons";
import { styled } from "@mui/material";
import { useResendInviteMutation } from "api/teammates";

const InviteBox = styled(ButtonBase)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  cursor: "pointer",
}));

const InviteButton = styled(Button)(() => ({
  background: "#E9F8F3",
  color: "#25B883",
  padding: "4px 16px",
  borderRadius: "20px",
  textTransform: "capitalize",
  display: "inline-flex",
  alignItems: "center",
}));
export const ResendInvite = ({ data }) => {
  const { mutate: resendInvite, isPending } = useResendInviteMutation();

  const handleResendInvite = () => {
    resendInvite({
      data: {
        accountId: data?.accountId,
      },
    });
  };

  if (data.activationStatus === "PENDING") {
    return (
      <InviteBox onClick={handleResendInvite} disabled={isPending}>
        {isPending ? (
          <CircularProgress size={18} sx={{ color: "primary.main" }} />
        ) : (
          <ResendIcon />
        )}
        Resend Invite
      </InviteBox>
    );
  }
  return <InviteButton>Completed</InviteButton>;
};
