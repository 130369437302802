import { useLocation } from "react-router-dom";

export const useMatchMenuOrSubMenu = (module) => {
  const { submenu, to } = module;
  const location = useLocation();
  const hasSubmenu = Boolean(submenu) && Boolean(submenu?.length);
  let match = false;
  match = location.pathname === to;
  if (hasSubmenu) {
    match = submenu.reduce((alreadyMatch, itx) => {
      return alreadyMatch || itx.to === location.pathname;
    }, false);
  }
  return match;
};
