import React from "react";
import { Box } from "@mui/material";

import { Typography } from "components/Typography";
import { useAuthContext } from "context/AuthContext";

import { DashboardContent } from "./components/DashboardContent";

export function Dashboard() {
  const { userProfile } = useAuthContext();
  return (
    <Box>
      <Typography
        variant="dashboardHeading"
        gutterBottom
        sx={{ marginBottom: 3 }}
      >
        Welcome back {userProfile?.account?.fullname},
      </Typography>
      <DashboardContent />
    </Box>
  );
}
