import SvgIcon from "@mui/material/SvgIcon";

export const ExportIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M15.2605 22.2503H8.74047C3.83047 22.2503 1.73047 20.1503 1.73047 15.2403V15.1103C1.73047 10.6703 3.48047 8.53027 7.40047 8.16027C7.80047 8.13027 8.18047 8.43027 8.22047 8.84027C8.26047 9.25027 7.96047 9.62027 7.54047 9.66027C4.40047 9.95027 3.23047 11.4303 3.23047 15.1203V15.2503C3.23047 19.3203 4.67047 20.7603 8.74047 20.7603H15.2605C19.3305 20.7603 20.7705 19.3203 20.7705 15.2503V15.1203C20.7705 11.4103 19.5805 9.93027 16.3805 9.66027C15.9705 9.62027 15.6605 9.26027 15.7005 8.85027C15.7405 8.44027 16.0905 8.13027 16.5105 8.17027C20.4905 8.51027 22.2705 10.6603 22.2705 15.1303V15.2603C22.2705 20.1503 20.1705 22.2503 15.2605 22.2503Z"
        fill="currentColor"
      />
      <path
        d="M12 15.7491C11.59 15.7491 11.25 15.4091 11.25 14.9991V3.61914C11.25 3.20914 11.59 2.86914 12 2.86914C12.41 2.86914 12.75 3.20914 12.75 3.61914V14.9991C12.75 15.4091 12.41 15.7491 12 15.7491Z"
        fill="#292D32"
      />
      <path
        d="M15.3498 6.59945C15.1598 6.59945 14.9698 6.52945 14.8198 6.37945L11.9998 3.55945L9.17984 6.37945C8.88984 6.66945 8.40984 6.66945 8.11984 6.37945C7.82984 6.08945 7.82984 5.60945 8.11984 5.31945L11.4698 1.96945C11.7598 1.67945 12.2398 1.67945 12.5298 1.96945L15.8798 5.31945C16.1698 5.60945 16.1698 6.08945 15.8798 6.37945C15.7398 6.52945 15.5398 6.59945 15.3498 6.59945Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
