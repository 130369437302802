import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Drawer as MuiDrawer } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import List from "@mui/material/List";

import { SIDEBAR_DRAWER_WIDTH } from "constants/layout";
import logo from "assets/logo.png";
import { NavLink } from "./components/NavLink";
import { navList } from "./utils/navList";
import { Logout } from "../Logout/Logout";
import { LogoutIcon } from "components/Icons";

const DrawerContainer = styled(MuiDrawer)(({ theme }) => ({
  width: SIDEBAR_DRAWER_WIDTH,
  flexShrink: 0,
  display: "flex",
  "& .MuiDrawer-paper": {
    width: SIDEBAR_DRAWER_WIDTH,
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.sidebar,
    borderRight: "0px",
  },
}));

const LogoContainer = styled(Box)(() => ({
  padding: "20px",
}));

export const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const openLogout = () => setOpen(true);
  const closeLogout = () => setOpen(false);

  return (
    <DrawerContainer variant="permanent" anchor="left">
      <LogoContainer>
        <img src={logo} alt="Tap" />
      </LogoContainer>
      <Box component={"nav"} sx={{ px: "22px" }}>
        <List>
          {navList.sectionOne.map((module, i) => {
            return <NavLink key={i} module={module} />;
          })}
        </List>
      </Box>
      <Divider
        sx={{ marginTop: "30px", marginBottom: "20px", borderColor: "#EBEEEF" }}
      />
      <Box component={"nav"} sx={{ px: "22px" }}>
        <List>
          {navList.sectionTwo.map((module, i) => {
            return <NavLink key={i} module={module} />;
          })}
          <NavLink
            module={{
              title: "Logout",
              icon: LogoutIcon,
              children: null,
              onClick: openLogout,
            }}
          />
        </List>
      </Box>
      <Logout open={open} closeLogout={closeLogout} />
    </DrawerContainer>
  );
};
