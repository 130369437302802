import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  styled,
  CircularProgress,
} from "@mui/material";

import { Modal } from "components/Modal";
import { useDisableTwoFa } from "api/settings";

const Title = styled("h2")(() => ({
  fontSize: "20px",
  fontFamily: "BR Firma",
  fontWeight: 600,
  textAlign: "center",
  paddingBottom: "10px",
}));

const Wrapper = styled(Box)(() => ({
  fontFamily: "BR Firma",
  fontSize: "16px",
}));

export const DisableTwoFactorAuthentication = () => {
  const [isOpen, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: disableTwoFa, isPending } = useDisableTwoFa();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDisableSetup = () => {
    disableTwoFa(
      {},
      {
        onSuccess: (res) => {
          handleCloseModal();
          enqueueSnackbar("Two-Factor Authencation Disabled", {
            variant: "success",
          });
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      }
    );
  };

  return (
    <Box sx={{ width: "500px", paddingX: "50px" }}>
      <Button
        disableRipple
        variant="contained"
        sx={{
          width: "100%",
          margin: "30px 0px",
          textTransform: "capitalize",
        }}
        size="large"
        onClick={() => setOpen(true)}
      >
        Disable Two-Factor Authentication
      </Button>
      <Modal open={isOpen} handleClose={handleCloseModal}>
        <Title>Disable Two-Factor Authentication</Title>

        <Wrapper>
          <Typography variant="body" lineHeight="25px">
            Are you sure you want to disable Two-Factor Authentication?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button
              onClick={handleCloseModal}
              sx={{ mr: 1 }}
              disabled={isPending}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDisableSetup}
              disabled={isPending}
              endIcon={isPending ? <CircularProgress size={20} /> : null}
            >
              Confirm
            </Button>
          </Box>
        </Wrapper>
      </Modal>
    </Box>
  );
};
