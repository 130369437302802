import SvgIcon from "@mui/material/SvgIcon";

export const SearchIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M9.58268 18.1243C4.87435 18.1243 1.04102 14.291 1.04102 9.58268C1.04102 4.87435 4.87435 1.04102 9.58268 1.04102C14.291 1.04102 18.1243 4.87435 18.1243 9.58268C18.1243 14.291 14.291 18.1243 9.58268 18.1243ZM9.58268 2.29102C5.55768 2.29102 2.29102 5.56602 2.29102 9.58268C2.29102 13.5993 5.55768 16.8743 9.58268 16.8743C13.6077 16.8743 16.8743 13.5993 16.8743 9.58268C16.8743 5.56602 13.6077 2.29102 9.58268 2.29102Z"
        fill="currentColor"
      />
      <path
        d="M18.3326 18.9576C18.1742 18.9576 18.0159 18.8992 17.8909 18.7742L16.2242 17.1076C15.9826 16.8659 15.9826 16.4659 16.2242 16.2242C16.4659 15.9826 16.8659 15.9826 17.1076 16.2242L18.7742 17.8909C19.0159 18.1326 19.0159 18.5326 18.7742 18.7742C18.6492 18.8992 18.4909 18.9576 18.3326 18.9576Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
