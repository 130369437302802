import { useState } from "react";
import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";

import { Typography } from "components/Typography";

const TextField = styled(MuiTextField)(({ error, theme }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: error
      ? `${theme.palette.error.main} !important`
      : "#EBEDEF !important",
  },
  "&:has(input:-internal-autofill-selected)": {
    backgroundColor: "rgb(232, 240, 254) !important",
  },
}));

export const TextInput = ({ label, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <FormControl fullWidth>
      <Typography variant="loginLabel" m={1} as="label">
        {label}
      </Typography>
      <TextField
        size="small"
        {...rest}
        type={showPassword ? "text" : rest.type}
        InputProps={{
          ...(rest.type === "password" && {
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                disableRipple
              >
                <Typography variant="loginLabel" sx={{ color: "#131419" }}>
                  {showPassword ? "Hide" : "Show"}
                </Typography>
              </IconButton>
            ),
          }),
          ...rest.InputProps,
        }}
      />
    </FormControl>
  );
};
