import { Grid, Button, Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { TextInput } from "components/TextInput";
import { Typography } from "components/Typography";
import {
  useActivationMutation,
  useGetActivationStatusQuery,
} from "api/collaborate";
import { formatRequestPayload } from "utils/helpers";

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

const Content = styled(Grid)(() => ({
  width: "344px",
}));

export const Collaborate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate;

  const { isLoading, isError } = useGetActivationStatusQuery(id);

  const { mutate: activate, isPending } = useActivationMutation();

  const formik = useFormik({
    initialValues: {
      fullname: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required(),
      password: Yup.string().required(),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password does not match")
        .required(),
    }),
    onSubmit: (values) => {
      delete values.confirmPassword;
      activate(
        { id, data: formatRequestPayload(values) },
        {
          onSuccess: (res) => {
            enqueueSnackbar(res.message, {
              variant: "success",
            });
            navigate("/login");
          },
          onError: (error) => {
            enqueueSnackbar(error.message, { variant: "error" });
          },
        }
      );
    },
  });

  if (isLoading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container flexDirection="column" rowGap="5px">
        <Typography variant="emailHeading">
          Ooops!!! Invalid or expired link
        </Typography>
        <Typography fontStyle="italic">
          Looks the invitation link has expired or could be invalid, reach out
          to the admin who invited to resend a new invitation link
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Typography variant="loginHeading" component="h2">
          Collaborate
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <TextInput
            label="Full Name"
            type="text"
            {...formik.getFieldProps("fullname")}
            error={
              Boolean(formik.touched.fullname) &&
              Boolean(formik.errors.fullname)
            }
          />
          <TextInput
            label="Password"
            type="password"
            {...formik.getFieldProps("password")}
            error={
              Boolean(formik.touched.password) &&
              Boolean(formik.errors.password)
            }
          />
          <TextInput
            label="Confirm Password"
            type="password"
            {...formik.getFieldProps("confirmPassword")}
            error={
              Boolean(formik.touched.confirmPassword) &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            disabled={isPending}
            disableRipple
            sx={{
              textTransform: "capitalize",
              marginTop: "20px",
            }}
          >
            Collaborate
            {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
          </Button>
        </form>
      </Content>
    </Container>
  );
};
