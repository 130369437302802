import SvgIcon from "@mui/material/SvgIcon";

export const CloseModalIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="12" fill="white" />
      <path
        d="M15.6004 8.40039L8.40039 15.6004"
        stroke="#23203D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.40039 8.40039L15.6004 15.6004"
        stroke="#23203D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
