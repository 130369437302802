import { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import OtpInput from "react-otp-input";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

import { Typography } from "components/Typography";
import Email from "../../assets/sms-notification.png";
import { useForgotPassword, useVerifyOtp } from "api/forgotPassword";
import { formatRequestPayload } from "utils/helpers";

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

export const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const { mutate: forgotPasswordRequest } = useForgotPassword();
  const { mutate: verifyOtpRequest, isPending } = useVerifyOtp();
  const { enqueueSnackbar } = useSnackbar();
  const [otpTimer, setOtpTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
 
  

  const startTimer = () => {
    setOtpTimer(60);
    setIsTimerActive(true); 
  };


  useEffect(() => {
    let intervalId;

    if (isTimerActive) {
      intervalId = setInterval(() => {
        setOtpTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            setIsTimerActive(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerActive]);



  const handleResendOtp = () => {
    startTimer(); 
    forgotPasswordRequest(formatRequestPayload({ email: location.state?.email }), {
      onSuccess: (res) => {
        enqueueSnackbar("OTP sent to your email", {
          variant: "success",
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    });
  };



 const formik = useFormik({
  initialValues: {
    otp: "",
  },
  validationSchema: Yup.object({
    otp: Yup.string()
      .required("OTP is required")
      .length(4, "OTP must be exactly 4 characters"),
  }),
  onSubmit: (values) => {
    const data = {
      ...values,
      email: location.state?.email,
      otpToken: location?.state?.otpToken,
    };

    verifyOtpRequest(formatRequestPayload(data), {
      onSuccess: (res) => {
        if (!res?.data?.otpToken) {
          throw new Error("OTP token is missing in the response");
        }

        enqueueSnackbar("Verification successful", {
          variant: "success",
        });

        navigate("/reset-password", {
          state: {
            email: location?.state?.email,
            otpToken: res?.data.otpToken,
          },
        });
      },
      onError: (error) => {
        console.log("Error received from server:", error.message);
        
        if (error.message === "Invlid OTP or expired") {
          enqueueSnackbar("Please request a new one.", {
            variant: "error",
          });
      
          navigate("/request-new-otp");
        } else if (error.message === "Otp token is missing in this response") { 
          enqueueSnackbar("There was an issue with the OTP. Please request a new one.", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("There was an issue with the OTP. Please request a new one.", { variant: "error" });
        }
         },
    }); 
  },
});


  const handleOtpChange = (otp) => {
    setOtp(otp);
    formik.setFieldValue("otp", otp);
  };

  if (!location.state?.email) {
    return <Navigate to="/login" replac />;
  }

  return (
    <Container>
      <Box sx={{ width: "344px", textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              background: "#E8EEF4",
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={Email}
              alt="Email"
              style={{ width: "40px", height: "40px" }}
            />
          </div>
        </div>
        <Typography variant="loginHeading" style={{ padding: "20px 0" }}>
          Check your email
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          style={{ paddingTop: "10px" }}
        >
          <Typography variant="dashboardBody">
            <p> We have sent an OTP to your email address.</p>
          </Typography>

          <Typography variant="dashboardBody" style={{ padding: "20px 0" }}>
            <p> Please enter OTP pin</p>
          </Typography>
          <div>
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              numInputs={4}
              renderSeparator={<span> </span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "16px",
                    textAlign: "center",
                    border: "1px solid #2D75B6",
                    borderRadius: "5px",
                    margin: "10px",
                  }}
                />
              )}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            disableRipple
            disabled={!formik.isValid || isPending} 
            sx={{
              width: "100%",
              margin: "20px 0px",
              textTransform: "capitalize",
            }}
            size="large"
          >
            Verify
            {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
          </Button>
          <small>
            Didn’t receive an OTP yet?{" "}
        
            
        <button
          onClick={handleResendOtp}
          style={{ color: "#2D75B6", cursor: "pointer", border:"0", background:"#fff" }}
          disabled={isTimerActive ? true : false} 
  
        >
          Resend {otpTimer > 0 && `( ${otpTimer}s)`} {" "}
        </button>{" "}

          
          </small>
        </form>
      </Box>
    </Container>
  );
};