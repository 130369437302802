import { useQuery } from "@tanstack/react-query";

import { getFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";
import * as queryKeys from "utils/queryKeys";
import { generateUrlSearchString } from "./utils/generateUrlSearchString";

const getTransactionsRequest = async (searchParams) =>
  await getFetch(`${baseURL}/transactions?${searchParams}`);

export const useGetTransactionsQuery = (params) => {
  const searchParams = generateUrlSearchString(params);
  return useQuery({
    queryFn: () => getTransactionsRequest(searchParams),
    queryKey: [queryKeys.transactions, { searchParams }],
    select: (data) => data?.data,
  });
};

const getTransactionDetailsRequest = async (transactionId) =>
  await getFetch(`${baseURL}/transactions/${transactionId}`);

export const useGetTransactionDetailsQuery = (transactionId) =>
  useQuery({
    queryFn: () => getTransactionDetailsRequest(transactionId),
    queryKey: [queryKeys.transactionDetails, { transactionId }],
    select: (data) => data?.data,
    enabled: !!transactionId,
  });
