export const teams = "teams";
export const collaborate = "collaborate";
export const Activities = "activities";
export const Settings = "settings";
export const Businesses = "businesses";
export const Banks = "banks";
export const BankAccounts = "bank-account";
export const BankDefault = "default-account";
export const BusinessesApiKey = "business-api-key";
export const ApiKey = "apikey";
export const Roles = "roles";
export const Permissions = "permissions";
export const resendInvite = "resend-invite";
export const transactions = "transactions";
export const transactionDetails = "transaction-details";
export const BusinessDetails = "business-details";
export const Dashboard = "dashboard";
