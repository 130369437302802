import { useState, useMemo } from "react";
import { Box, Button as MuiButton, InputBase } from "@mui/material";
import { styled } from "@mui/material";
import { format, getUnixTime } from "date-fns";

import { SearchIcon, ExportIcon } from "components/Icons";
import { Typography } from "components/Typography";
import { DataGrid } from "components/DataGrid";
import { useDebounce } from "hooks/useDebounce";
import { DateRangePicker } from "components/DateRangePicker";
import { useGetTransactionsQuery } from "api/transactions";

import { TransactionMenuOption } from "./components/TransactionMenuOption";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  border: "1.5px solid #EFF1F4",
  borderRadius: "8px",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const Button = styled(MuiButton)(() => ({
  borderColor: "#EFF1F4",
  color: "black",
  height: "40px",
  textTransform: "capitalize",
  ".MuiButtonBase-root-MuiButton-root": {
    borderColor: "#EFF1F4 !important",
  },
}));

const columns = [
  { field: "businessName", headerName: "BUSINESS NAME", flex: 1 },
  {
    field: "totalAmount",
    headerName: "TOTAL AMOUNT",
    flex: 1,
  },
  { field: "id", headerName: "TRANSACTION ID", flex: 1 },
  {
    field: "date",
    headerName: "DATE & TIME",
    type: "number",
    flex: 1,
  },
  {
    field: "more",
    headerName: "",
    align: "center",
    renderCell: (grid) => <TransactionMenuOption transactionId={grid.row.id} />,
  },
];

export const Transactions = () => {
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const debouncedSearchValue = useDebounce(search, 500);

  const params = useMemo(
    () => ({
      limit,
      page,
      search: debouncedSearchValue,
      to,
      from,
      sort,
    }),
    [debouncedSearchValue, from, to, sort, limit, page]
  );

  const { data, isLoading } = useGetTransactionsQuery(params);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSelectRange = (range) => {
    setFrom(getUnixTime(range.startDate));
    setTo(getUnixTime(range.endDate));
  };

  const rows = data?.transactions?.map((transactions) => ({
    id: transactions.transId,
    businessName: transactions.businessName,
    totalAmount: transactions.amount,
    date: format(new Date(transactions.createdOn * 1000), "dd/MM/yyyy hh:mm a"),
  }));

  return (
    <Box>
      <Wrapper
        sx={{
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Wrapper>
          <Typography variant="dashboardHeading">Transactions</Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={search}
              onChange={handleSearch}
            />
          </Search>
        </Wrapper>

        <Wrapper sx={{ columnGap: "8px" }}>
          <DateRangePicker handleSelectRange={handleSelectRange} />
          <Button variant="outlined">
            <Wrapper>
              <ExportIcon />
              <div style={{ marginTop: 5 }}> Export csv</div>
            </Wrapper>
          </Button>
        </Wrapper>
      </Wrapper>

      <DataGrid
        rows={rows || []}
        columns={columns}
        rowCount={data?.totalData || 0}
        initialState={{
          pagination: { paginationModel: { pageSize: limit } },
        }}
        loading={isLoading}
        checkboxSelection
        paginationMode="server"
        disableColumnMenu
        disableRowSelectionOnClick
        onPaginationModelChange={(value) => {
          setPage(value.page);
        }}
      />
    </Box>
  );
};
