export const generateUrlSearchString = (paramObj) => {
  const searchParams = new URLSearchParams();
  const filteredParams = Object.entries(paramObj).filter(([_, value]) => {
    return Boolean(value);
  });
  filteredParams.forEach(([key, value]) => {
    searchParams.append(key, value);
  });
  return searchParams.toString();
};
