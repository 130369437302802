import { useQuery } from "@tanstack/react-query";
import { getFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";
import * as queryKeys from "utils/queryKeys";

const getDashboardRequest = async () => await getFetch(`${baseURL}/dashboard`);

export const useGetDashboardQuery = () =>
  useQuery({
    queryFn: getDashboardRequest,
    queryKey: [queryKeys.Dashboard],
    select: (data) => data?.data,
  });

// .transactionSummary{} and .balances[{}]
