import * as React from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

import { DataGrid } from "components/DataGrid";
import { useGetBusinessDetails } from "api/business";
import { BusinessMenuOption } from "./components/BusinessMenuOption";

const generateColumns = ({ businessId }) => [
  {
    field: "amount",
    headerName: "Total Amount",
    flex: 1,
  },
  {
    field: "transId",
    headerName: "Transaction Id",
    flex: 1,
  },

  {
    field: "createdOn",
    headerName: "Date & Time",
    flex: 1,
    valueFormatter: ({ value }) =>
      format(new Date(Number(value) * 1000), "dd/MM/yyyy hh:mm a"),
  },
  {
    field: "more",
    headerName: "",
    align: "center",
    renderCell: (grid) => (
      <BusinessMenuOption
        options={{ businessId, transactionId: grid.row.transId }}
      />
    ),
  },
];

export function BusinessDetailsTable() {
  const { businessId } = useParams();
  const { data = [], isLoading } = useGetBusinessDetails(businessId);

  return (
    <DataGrid
      rows={data || []}
      columns={generateColumns({ businessId })}
      loading={isLoading}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      pageSizeOptions={[10, 25, 50]}
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
}
