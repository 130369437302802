import { Box, TableContainer, styled } from "@mui/material";
import { format } from "date-fns";
import React from "react";

import {
  CustomTable,
  EXPAND_DETAILS_COLUMN,
} from "components/CustomTable/CustomTable";

const StatusStyle = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  borderRadius: "20px",
  padding: "5px 2px",
}));

const StyledCustomTable = styled(CustomTable)(() => ({
  ".MuiTableRow-head .MuiTableCell-root": {
    borderBottom: "none",
    paddingLeft: "0px",
    paddingRight: "0px",
    color: "#7C7A78",
  },
  ".MuiTableRow-root .MuiTableCell-root": {
    borderBottom: "none",
    paddingLeft: "0px",
  },
}));

export const TransactionDetailsTable = ({ data }) => {
  const rows = data?.map((transactions) => ({
    id: transactions.transId,
    description: transactions.description,
    amount: transactions.amount,
    date: format(new Date(transactions.createdOn * 1000), "dd/MM/yyyy hh:mm a"),
    status: transactions.status,
    reference: transactions.transIdRef,
    bank: transactions.bankName,
    mode: transactions.paymentMode,
    accountNo: transactions.accountNo,
    accountName: transactions.accountName,
  }));

  const columns = [
    { field: "description", headerName: "DESCRIPTION" },
    {
      field: "amount",
      headerName: "AMOUNT",
    },
    {
      field: "date",
      headerName: "DATE & TIME",
    },
    {
      field: "status",
      headerName: "STATUS",
      renderCell: (row) => {
        if (row.status === "1") {
          return (
            <StatusStyle
              sx={{
                backgroundColor: "#E9F8F3",
                color: "#25B883",
                width: "70%",
              }}
            >
              Successful
            </StatusStyle>
          );
        } else {
          return (
            <StatusStyle
              sx={{
                backgroundColor: "#FEEFF0",
                color: "#F25A68",
                width: "50%",
              }}
            >
              Failed
            </StatusStyle>
          );
        }
      },
    },
    {
      ...EXPAND_DETAILS_COLUMN,
    },
  ];
  const detailsColumns = [
    { field: "reference", headerName: "REFERENCE NUMBER" },
    {
      field: "bank",
      headerName: "BANK NAME",
    },
    {
      field: "mode",
      headerName: "PAYMENT MODE",
    },
    {
      field: "accountNo",
      headerName: "BANK ACCOUNT NO",
    },
    {
      field: "accountName",
      headerName: "ACCOUNT NAME",
    },
  ];

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <CustomTable
        columns={columns}
        rows={rows}
        expandableRowComponent={({ row }) => {
          return (
            <StyledCustomTable
              columns={detailsColumns}
              rows={[row]}
              size="small"
            />
          );
        }}
      />
    </TableContainer>
  );
};
