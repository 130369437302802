import { useMutation } from "@tanstack/react-query";

import { postFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";

const loginRequest = async (data) =>
  await postFetch(`${baseURL}/account/login`, "POST", {}, data);

export const useLogin = (options) =>
  useMutation({ mutationFn: loginRequest, ...options });

const twoFaLoginRequest = async (data) =>
  await postFetch(`${baseURL}/account/complete-login`, "POST", {}, data);

export const useTWoFaLogin = (options) =>
  useMutation({ mutationFn: twoFaLoginRequest, ...options });
