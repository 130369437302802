import React, { useState } from "react";

import { SummaryDetails } from "./SummaryDetails";
import { Permission } from "./Permission";
import { Modal } from "components/Modal";
import { ChangeRole } from "./ChangeRole";

const MODAL_CONTENT = {
  PERMISSION: "PERMISSION",
  SUMMARY_DETAILS: "SUMMARY_DETAILS",
  CHANGE_ROLE: "CHANGE_ROLE",
};

export const TeamMateDetails = ({ data, open, onClose }) => {
  const [content, setContent] = useState(MODAL_CONTENT.SUMMARY_DETAILS);

  const PermissionModal = () => {
    setContent(MODAL_CONTENT.PERMISSION);
  };

  const ChangeRoleModal = () => {
    setContent(MODAL_CONTENT.CHANGE_ROLE);
  };

  return (
    <Modal
      open={open}
      handleClose={() => {
        onClose();
        setContent(MODAL_CONTENT.SUMMARY_DETAILS);
      }}
    >
      {content === MODAL_CONTENT.SUMMARY_DETAILS && (
        <SummaryDetails
          handlePermission={PermissionModal}
          handleChangeRole={ChangeRoleModal}
          data={data}
        />
      )}
      {content === MODAL_CONTENT.PERMISSION && <Permission />}
      {content === MODAL_CONTENT.CHANGE_ROLE && <ChangeRole />}
    </Modal>
  );
};
