import { Box as MuiBox, Button as MuiButton, styled } from "@mui/material";
import * as Yup from "yup";

import { InfoIcon } from "components/Icons/InfoIcon";
import { TextInput } from "components/TextInput";
import { Typography } from "components/Typography";
import { Modal } from "components/Modal";
import { useResetBusinessApiMutation } from "api/business";
import { useFormik } from "formik";
import { formatRequestPayload } from "utils/helpers";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";

const Form = styled(TextInput)(() => ({
  marginBottom: "10px",
  ".css-1jvdvmx": {
    backgroundColor: "red",
  },
}));

const Box = styled(MuiBox)(() => ({
  padding: "20px 10px",
  backgroundColor: "#F9FAFA",
  border: "1px solid #EBEDEF",
  borderRadius: "5px",
  margin: "40px 0px",
  display: "flex",
  gap: "10px",
}));

const Button = styled(MuiButton)(() => ({
  padding: "10px 0px",
  margin: "30px 0px",
  textTransform: "capitalize",
  "&:hover": { backgroundColor: "#2D75B6", boxShadow: "none" },
}));

const Label = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "500",
}));
export const ResetApi = ({ reset, closeReset, businessId }) => {
  const { mutate: resetBusinesApi } = useResetBusinessApiMutation();
  const query = useQueryClient();

  const formik = useFormik({
    initialValues: {
      resetReason: "",
      password: "",
    },
    validationSchema: Yup.object({
      resetReason: Yup.string().required(),
      password: Yup.string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      resetBusinesApi(
        { businessId, data: formatRequestPayload(values) },
        {
          onSuccess: (res) => {

            query.invalidateQueries({ queryKey: [businessId] });

            enqueueSnackbar(res.message, {
              variant: "success",
            });
            closeReset();
            resetForm();
          },
          onError: (error) => {
            enqueueSnackbar(error.message, { variant: "error" });
          },
        }
      );
    },
  });
  return (
    <>
      <Modal open={reset} handleClose={closeReset}>
        <Typography
          variant="emailHeading"
          align="center"
          id="modal-modal-title"
          component="h2"
          sx={{ fontSize: "20px", my: "25px" }}
        >
          Reset API Key
        </Typography>

        <Box>
          <InfoIcon />
          <Typography variant="loginfooter">
            This action will deactivate your current API key and immediately
            generate a new one.
          </Typography>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Label>Why do you want to reset your API key?</Label>
          <Form
            {...formik.getFieldProps("resetReason")}
            error={
              Boolean(formik.touched.resetReason) &&
              Boolean(formik.errors.resetReason)
            }
            type="text"
            fullWidth
            multiline
            rows={2}
          />

          <Label>Enter password to confirm this action</Label>
          <Form
            {...formik.getFieldProps("password")}
            error={
              Boolean(formik.touched.password) &&
              Boolean(formik.errors.password)
            }
            type="password"
          />

          <Button type="submit" variant="contained" fullWidth disableRipple>
            Reset API key
          </Button>
        </form>
      </Modal>
    </>
  );
};
