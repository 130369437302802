import { useMutation } from "@tanstack/react-query";

import { postFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";

const forgotPasswordRequest = async (data) =>
  await postFetch(`${baseURL}/account/forgot-password`, "POST", {}, data);

export const useForgotPassword = (options) =>
  useMutation({ mutationFn: forgotPasswordRequest, ...options });

  const resetPasswordRequest = async (data) =>
  await postFetch(`${baseURL}/account/reset-password`, "POST", {}, data);

export const useResetPassword = (options) =>
  useMutation({ mutationFn: resetPasswordRequest, ...options });


  
  const verifyOtpRequest = async (data) =>
  await postFetch(`${baseURL}/account/verify-otp`, "POST", {}, data);

export const useVerifyOtp = (options) =>
  useMutation({ mutationFn: verifyOtpRequest, ...options });
