import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFetch, postFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";
import * as queryKeys from "utils/queryKeys";

const getCollaborationStatusRequest = async (id) =>
  await getFetch(`${baseURL}/teams/invite/activation/${id}`);

export const useGetActivationStatusQuery = (id) =>
  useQuery({
    queryFn: () => getCollaborationStatusRequest(id),
    queryKey: [queryKeys.collaborate, { id }],
    select: (data) => data?.data.collaborate,
    enabled: !!id,
  });

const activationRequest = async (id, data) =>
  await postFetch(`${baseURL}/teams/invite/activation/${id}`, "POST", {}, data);

export const useActivationMutation = (options) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => activationRequest(id, data),
    onSuccess: (_, { id }) => {
      query.invalidateQueries({ queryKey: [queryKeys.collaborate, { id }] });
    },
    ...options,
  });
};
