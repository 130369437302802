import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material";

import { NoRowOverlay } from "components/DataGrid/components/NoRowOverlay";

const DataTable = styled(DataGrid)(() => ({
  font: "14px",
  width: "100%",
  border: "none",

  ".MuiDataGrid-columnHeaderTitle": {
    textTransform: "uppercase",
    fontWeight: "600",
    borderBottom: "none",
  },
  " .MuiDataGrid-withBorderColor ": {
    borderColor: "#EBEEEF !important",
  },

  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },
  ".MuiSvgIcon-root": {
    color: "#BFCBCA !important",
  },

  "& .MuiDataGrid-sortIcon": {
    display: "none",
  },
  ".MuiDataGrid-cell--withRightBorder": {
    border: "none",
  },
  ".MuiTablePagination-toolbar": {
    display: "none ! important",
  },
  " .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  ".MuiDataGrid-overlayWrapperInner": {
    height: "200px !important",
  },
  ".MuiDataGrid-virtualScrollerContent": {
    minHeight: "200px !important",
  },
}));

const COLUMNS = [
  {
    field: "bank",
    headerName: "Bank",
    flex: 1,
  },
  {
    field: "accountName",
    headerName: "Account Name",
    flex: 1,
  },
  {
    field: "accountNumber",
    headerName: "Account Number",
    flex: 1,
  },
  {
    field: "commonName",
    headerName: "Common Name",
    flex: 1,
  },
  {
    field: "availableBalance",
    headerName: "Balance",
    flex: 1,
  },
];

export function BalanceSummaryTable({ balances }) {
  const data = balances.map((balance, i) => ({
    id: i + 1,
    bank: balance.bank,
    accountName: balance.accountName,
    accountNumber: balance.accountNumber,
    commonName: balance.commonName,
    availableBalance: balance.availableBalance,
  }));

  return (
    <DataTable
      rows={data}
      columns={COLUMNS}
      checkboxSelection
      enablePagination={false}
      disableColumnMenu={true}
      disableRowSelectionOnClick={true}
      autoHeight
      slots={{ noRowsOverlay: NoRowOverlay }}
    />
  );
}
