import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { TextInput } from "components/TextInput";
import { formatRequestPayload } from "utils/helpers";
import { usePasswordChangeMutation } from "api/settings";

export const ChangePassword = () => {
  const { mutate: UpdatePassword, isPending } = usePasswordChangeMutation();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current Password is required"),
      newPassword: Yup.string().required("New Password is required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm New Password is required"),
    }),
    onSubmit: (values) => {
      delete values.confirmNewPassword;
      UpdatePassword(formatRequestPayload(values), {
        onSuccess: (res) => {
          enqueueSnackbar("Password changed successfully", {
            variant: "success",
          });
          formik.resetForm({});
        },
        onError: (res) => {
          // Handle error if needed
        },
      });
    },
  });

  const { handleSubmit } = formik;

  return (
    <Box sx={{ width: "500px", paddingX: "50px" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(formik.onSubmit);
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flexStart",
          gap: "30px",
        }}
      >
        <TextInput
          label="Current Password"
          type="password"
          name="currentPassword"
          value={formik.values.currentPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            Boolean(formik.touched.currentPassword) &&
            Boolean(formik.errors.currentPassword)
          }
          helperText={
            formik.touched.currentPassword && formik.errors.currentPassword
          }
        />
        <TextInput
          label="New Password"
          type="password"
          name="newPassword"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            Boolean(formik.touched.newPassword) &&
            Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />
        <TextInput
          label="Confirm New Password"
          type="password"
          name="confirmNewPassword"
          value={formik.values.confirmNewPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            Boolean(formik.touched.confirmNewPassword) &&
            Boolean(formik.errors.confirmNewPassword)
          }
          helperText={
            formik.touched.confirmNewPassword &&
            formik.errors.confirmNewPassword
          }
        />
        <Button
          disableRipple
          type="submit"
          variant="contained"
          sx={{
            width: "100%",
            margin: "30px 0px",
            textTransform: "capitalize",
          }}
          size="large"
        >
          Save Changes
          {isPending && (
            <CircularProgress size={18} sx={{ ml: 0.5, color: "#fff" }} />
          )}
        </Button>
      </form>
    </Box>
  );
};
