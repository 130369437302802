import SvgIcon from "@mui/material/SvgIcon";

export const NavMenuArrowDownIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 13 13" {...props}>
      <path
        d="M6.49958 9.10034C6.12042 9.10034 5.74125 8.95409 5.45417 8.66701L1.9225 5.13534C1.76542
         4.97825 1.76542 4.71826 1.9225 4.56117C2.07958 4.40409 2.33958 4.40409 2.49667 4.56117L6.02833
          8.09284C6.28833 8.35284 6.71083 8.35284 6.97083 8.09284L10.5025 4.56117C10.6596 4.40409 10.9196
           4.40409 11.0767 4.56117C11.2338 4.71826 11.2338 4.97825 11.0767 5.13534L7.545 8.66701C7.25792 8.95409
            6.87875 9.10034 6.49958 9.10034Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
