import { useQuery } from "@tanstack/react-query";

import { getFetch } from "config/fetch";
import {baseURL} from 'constants/baseUrl';
import * as queryKeys from "utils/queryKeys";


const activityRequest = async (data) =>
  await getFetch(`${baseURL}/activity/logs`, "GET", {}, data);

export const useGetActivity = () =>
useQuery({ queryFn: activityRequest, queryKey:[queryKeys.Activities],
select: (data) => data?.data.activitylogs.activityLogs
});
