import { Box, Button, styled } from "@mui/material";
import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Typography } from "components/Typography";

const Title = styled("h2")(() => ({
  fontSize: "20px",
  fontFamily: "BR Firma",
  fontWeight: 600,
  textAlign: "center",
}));
const TextWrapper = styled(Box)(() => ({
  padding: "10px 0px",
}));

export const ChangeRole = () => {
  const [selectedrole, setSelectedRole] = useState("");

  const handleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ width: "100%", paddingBottom: "10px" }}>
        <Title>Change Role</Title>
      </FormControl>
      <div>
        <FormControl sx={{ width: "100%", py: "10px" }}>
          <TextWrapper>
            <Typography variant="loginLabel">Role </Typography>
          </TextWrapper>

          <Select
            labelId="select-label-1"
            id="select-1"
            value={selectedrole}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Select Role 1</em>
            </MenuItem>
            <MenuItem value="option1">Customer service</MenuItem>
            <MenuItem value="option2">Option 2</MenuItem>
            <MenuItem value="option3">Option 3</MenuItem>
          </Select>
        </FormControl>

        <Button
          disableRipple
          type="submit"
          variant="contained"
          sx={{
            width: "100%",
            margin: "30px 0px",
            textTransform: "capitalize",
          }}
          size="large"
        >
          Change role
        </Button>
      </div>
    </div>
  );
};
