import {
  Box as MuiBox,
  Modal as MuiModal,
  styled,
  IconButton,
} from "@mui/material";
import { CloseModalIcon } from "components/Icons";

const Box = styled(MuiBox)((theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 330,
  backgroundColor: "white",
  padding: "30px",
  borderRadius: "10px",
  alignItems: "center",
}));

export const Modal = ({ children, open, handleClose, containerStyle }) => {
  return (
    <MuiModal open={open} onClose={handleClose}>
      <Box sx={containerStyle}>
        <IconButton
          disableRipple
          onClick={handleClose}
          sx={{ position: "absolute", bottom: "100%", left: "92%" }}
        >
          <CloseModalIcon />
        </IconButton>
        {children}
      </Box>
    </MuiModal>
  );
};
