import React, { useState } from "react";
import { CardContent, Box, styled, Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import { format } from "date-fns";

import { Modal } from "components/Modal";
import { Permission } from "pages/Administration/Team/component/TeamMateDetails/components/Permission";
import { useGetRoles } from "api/roles";

const WidgetBox = styled(Card)(() => ({
  fontFamily: "BR Firma",
  borderRadius: "4px",
  border: "1px solid #F1F0F5",
  width: "47%", 
  height: "200px",
  boxShadow: "none",
  padding: "10px 0px",
  marginBottom: "20px",
}));

const Span = styled("span")(() => ({
  color: "#2D75B6",
  textDecoration: "underline",
  font: "12px",
  cursor: "pointer",
}));

const BoxContainer = styled(Box)(() => ({
  gap: "30px",
  display: "flex",
  flexWrap: "wrap",
}));

const RoleText = styled("p")(() => ({
  font: "18px",
  fontWeight: "700",
}));

const TextWrapper = styled("p")(() => ({
  font: "8px !important",
  color: "#50555E",
}));

const Wrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const BottomWrapper = styled("div")(() => ({
  paddingTop: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ModalContainer = styled(Box)(() => ({
  maxHeight: "80vh",
  overflowY: "auto",
  margin: "0 10px",
  padding: "30px",
  boxSizing: "border-box",
}));

export const CardContainer = () => {
  const [openPermissions, setOpenPermissions] = useState({});
  const [selectLabel] = useState(true);
  const { data = [], isLoading, isError, error } = useGetRoles();

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const togglePermissionModal = (roleId) => {
    setOpenPermissions((prevOpenPermissions) => ({
      ...prevOpenPermissions,
      [roleId]: !prevOpenPermissions[roleId],
    }));
  };

  const cards = data?.map((role) => ({
    id: role.roleId,
    name: role.name,
  }));

  const dateAndTime = new Date();
  const formattedDate = format(dateAndTime, "MMM dd','yyyy 'at' hh:mm a");

  return (
    <BoxContainer>
      {cards.map((card, index) => (
        <WidgetBox key={index}>
          <CardContent>
            <Wrapper>
              <RoleText>{card.name}</RoleText>
              <TextWrapper>last modified at {formattedDate}.</TextWrapper>
            </Wrapper>
            <BottomWrapper>
              <Avatar
                sx={{
                  background: "#F0E9E9",
                  color: "#8B5C5D",
                  width: "45px",
                  height: "45px",
                }}
              >
                {card.name
                  ? card.name
                      .split(" ")
                      .map((namePart) => namePart[0])
                      .join("")
                  : ""}
              </Avatar>
              <Span onClick={() => togglePermissionModal(card.id)}>
                See permission
              </Span>
            </BottomWrapper>
            <Modal
              open={openPermissions[card.id]}
              handleClose={() => togglePermissionModal(card.id)}
              containerStyle={{
                paddingLeft: 0,
                paddingRight: 0,
                width: "450px",
              }}
            >
              <ModalContainer>
                {selectLabel && (
                  <Permission name={card.name} roleId={card.id} />
                )}
              </ModalContainer>
            </Modal>
          </CardContent>
        </WidgetBox>
      ))}
    </BoxContainer>
  );
};
