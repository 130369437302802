import SvgIcon from "@mui/material/SvgIcon";

export const ChevronArrowDownIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 8" {...props}>
      <path
        opacity="0.6"
        d="M5.26545 7.20482C5.66137 7.63342 6.33863 7.63342 6.73455 7.20482L11.3776 2.17855C11.9693 1.53807 11.515 0.5 10.6431 0.5H1.35693C0.484997 0.5 0.0307255 1.53807 0.622376 2.17855L5.26545 7.20482Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
