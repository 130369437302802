import { Box, Button as MuiButton, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useParams } from "react-router-dom";

import { Typography } from "components/Typography";
import { BackArrowIcon } from "components/Icons";
import BusinessInfo from "./components/BusinessInfo";
import { useGetBusinessesQuery } from "api/business";

import { BusinessTransactionWithdrawalDetailsTable } from "./components/BusinessTransactionWithdrawalDetailsTable";

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.sidebar,
  display: "flex",
  color: "black",
  marginTop: "25px",
  marginBottom: "25px",
  "&:hover": { backgroundColor: "#fafafa" },
}));

export const BusinessTransactionWithdrawalDetails = () => {
  const navigate = useNavigate();
  const { businessId } = useParams();

  const { data: businesses } = useGetBusinessesQuery(businessId);

  const business = businesses?.find(
    (business) => business.businessId === businessId
  );
  const businessName = business?.name;

  return (
    <Box>
      {businessName && (
        <Typography variant="loginHeading">{businessName}</Typography>
      )}
      <Button
        variant="contained"
        disableRipple
        disableElevation
        onClick={() => navigate(-1)}
      >
        <BackArrowIcon />
        Back
      </Button>
      <BusinessInfo business={business} />
      <Typography variant="loginHeading">
        Transaction Withdrawal Details
      </Typography>
      <BusinessTransactionWithdrawalDetailsTable />
    </Box>
  );
};
