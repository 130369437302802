import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { MenuIcon } from "components/Icons";

export const BusinessMenuOption = ({ options }) => {
  const navigate = useNavigate();
  const {transactionId,businessId} = options
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = () => {
    navigate(`/businesses/${businessId}/transactions/${transactionId}`);
    handleClose();
  };

  return (
    <div>
      <MenuIcon onClick={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleItemClick} sx={{ fontSize: "13px" }}>
          View withdrawal details
        </MenuItem>
      </Menu>
    </div>
  );
};
