import { useQuery } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getFetch, postFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";
import * as queryKeys from "utils/queryKeys";

const roleRequest = async (data) =>
  await getFetch(`${baseURL}/roles`, "GET", {}, data);

export const useGetRoles = () =>
  useQuery({
    queryFn: roleRequest,
    queryKey: [queryKeys.Roles],
    select: (data) => data?.data?.roles,
  });

const permissionRequest = async (data) =>
  await getFetch(`${baseURL}/permissions`, "GET", {}, data);

export const useGetPermission = () =>
  useQuery({
    queryFn: permissionRequest,
    queryKey: [queryKeys.Permissions],
    select: (data) => data?.data?.permissions,
  });

const createRoleRequest = async (data) =>
  await postFetch(`${baseURL}/roles/`, "POST", {}, data);

export const useCreateRoleMutation = (options) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: createRoleRequest,
    onSuccess: () => {
      query.invalidateQueries({ queryKey: [queryKeys.Roles] });
    },
    ...options,
  });
};

const roleRequestById = async (data) => {
  const [, { roleId }] = data.queryKey;
  return await getFetch(
    `${baseURL}/roles/${roleId}/permissions`,
    "GET",
    {},
    data
  );
};

export const GetRolesById = (roleId) =>
  useQuery({
    queryFn: roleRequestById,
    queryKey: [queryKeys.Roles, { roleId }],
    select: (data) => data?.data?.rolePermissions,
  });
