export const TFA_MEDIUM = {
  Email: 0,
  AUTHENTICATOR_APP: 1,
  SMS: 2,
};

export const TFA_STATUS = {
  INITIATE: "INITIATE",
  NOTINITIATE: "NOTINITIATE",
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};
