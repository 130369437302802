import React, { useEffect, useState } from "react";
import { Link, useMatch, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";

import { TYPOGRAPHY_VARIANTS } from "components/Typography";
import { NavMenuArrowDownIcon, NavMenuArrowRightIcon } from "components/Icons";
import { useMatchMenuOrSubMenu } from "../hooks/useMatchMenuOrSubMenu";

const MenuListItemText = styled(ListItemText)(() => ({
  ".MuiTypography-root": {
    ...TYPOGRAPHY_VARIANTS["dashboardBody"],
  },
}));

const SubMenuListItemText = styled(ListItemText)(() => ({
  ".MuiTypography-root": {
    ...TYPOGRAPHY_VARIANTS["dashboardBody"],
    fontSize: "12px",
    fontWeight: 500,
  },
}));

const MenuListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => {
  const activeStyle = {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    transition: "background 0.5s ease",

    path: {
      color: "#fff",
    },
    "& .Dashboard-icon": {
      path: {
        stroke: "#fff",
      },
    },
  };

  return {
    borderRadius: "4px",
    paddingTop: "15px",
    paddingBottom: "15px",
    color: "#50555E",
    ...(active && activeStyle),
    "&:hover": activeStyle,
  };
});

const SubMenuListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }) => {
  const activeStyle = {
    color: "#23203D",
    backgroundColor: "inherit",
  };
  return {
    fontSize: 13,
    paddingLeft: "56px",
    color: "#50555E",
    marginBottom: "10px",
    ...(active && activeStyle),
    "&:hover": activeStyle,
  };
});

export const NavLink = ({ module }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const { title, icon: Icon, submenu, to, onClick } = module;

  let match = useMatchMenuOrSubMenu(module);
  const hasSubmenu = Boolean(submenu) && Boolean(submenu?.length);

  useEffect(() => {
    if (hasSubmenu) {
      setOpen(match);
    }
  }, [match, hasSubmenu, location]);

  const handleClick = (e) => {
    if (!Boolean(submenu)) {
      onClick?.();
      return;
    }
    e.preventDefault();
    setOpen((prev) => !prev);
  };
  return (
    <>
      <ListItem disablePadding sx={{ marginBottom: "15px" }}>
        <MenuListItemButton
          disableRipple
          active={match}
          LinkComponent={Link}
          to={to}
          onClick={handleClick}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Icon />
          </ListItemIcon>
          <MenuListItemText primary={title} />
          {(() => {
            if (!hasSubmenu) return null;
            return open ? (
              <NavMenuArrowDownIcon sx={{ fontSize: "18px" }} />
            ) : (
              <NavMenuArrowRightIcon sx={{ fontSize: "18px" }} />
            );
          })()}
        </MenuListItemButton>
      </ListItem>
      <Collapse in={open && hasSubmenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasSubmenu &&
            submenu.map((menu, i) => {
              return <SubNavLink key={i} menu={menu} />;
            })}
        </List>
      </Collapse>
    </>
  );
};

const SubNavLink = ({ menu }) => {
  const { to, title } = menu;
  const match = Boolean(useMatch(to));

  return (
    <SubMenuListItemButton
      disableRipple
      active={match}
      LinkComponent={Link}
      to={to}
    >
      <SubMenuListItemText primary={title} />
    </SubMenuListItemButton>
  );
};
