import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes, Navigate } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { Login } from "pages/Login";
import { Collaborate } from "pages/Collaborate";
import { Transactions } from "pages/Transactions";
import { Dashboard } from "pages/Dashboard/Dashboard";
import { Businesses } from "pages/Businesses";
import { Activity } from "pages/Activity";
import { BusinessTransactions } from "pages/Businesses/BusinessTransactions";
import { BusinessTransactionWithdrawalDetails } from "pages/Businesses/BusinessTransactionWithdrawalDetails";
import { Team } from "pages/Administration/Team";
import { Roles } from "pages/Administration/Roles";
import { Settings } from "pages/Settings";
import { ForgotPassword } from "pages/ForgotPassword";
import { ResetPassword } from "pages/ResetPassword/ResetPassword";
import { VerifyOtp } from "pages/VerifyOtp/VerifyOtp";
import { TransactionDetails } from "pages/Transactions/TransactionDetails";
import { Banks } from "pages/Banks";

import { useAuthContext } from "context/AuthContext";
import { AuthLayout, Layout } from "components/Layout";
import { theme } from "config/theme";

const ProtectedRoute = ({ redirectPath, children }) => {
  const { isLoggedIn } = useAuthContext();
  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ReactQueryDevtools initialIsOpen />

      <Routes>
        <Route
          element={
            <ProtectedRoute redirectPath="/login">
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route index path="/" element={<Dashboard />} />
          <Route path="/transactions">
            <Route index={true} element={<Transactions />} />
            <Route path=":id" element={<TransactionDetails />} />
          </Route>
          <Route path="/businesses">
            <Route index={true} element={<Businesses />} />
            <Route
              path=":businessId/transactions"
              element={<BusinessTransactions />}
            />
            <Route
              path=":businessId/transactions/:transactionId"
              element={<BusinessTransactionWithdrawalDetails />}
            />
          </Route>
          <Route path="/administration">
            <Route path="role-privileges" element={<Roles />} />
            <Route path="team-mate" element={<Team />} />
          </Route>
          <Route path="/activity-log" element={<Activity />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/banks" element={<Banks />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/collaborate/:id" element={<Collaborate />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
