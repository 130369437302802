import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Skeleton,
  Box,
} from "@mui/material";
import { GetRolesById } from "api/roles";

const Title = styled("h2")(() => ({
  fontSize: "20px",
  fontFamily: "BR Firma",
  fontWeight: 600,
  textAlign: "center",
}));

export function Permission({ roleId, name }) {
  const { data: permissionLists = [], isLoading } = GetRolesById(roleId);

  if (isLoading) {
    return (
      <Box>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
      </Box>
    );
  }

  return (
    <>
      <Title>{name} Permission</Title>
      <List>
        {permissionLists.map(({ name }, index) => {
          return (
            <ListItem key={index}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  value={name}
                  checked={true}
                  disabled
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
