import { styled, Box, Button, InputBase } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { format } from "date-fns";

import { Typography } from "components/Typography";
import { ExportIcon, SearchIcon } from "components/Icons";
import { BusinessOptions } from "./components/BusinessOptions";
import { AddBusiness } from "./components/AddBusiness";
import { ApiKey } from "./components/ApiKey/ApiKey";
import { useGetBusinessesQuery } from "api/business";
import { DataGrid } from "components/DataGrid";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const CsvButton = styled(Button)(() => ({
  borderColor: "#EFF1F4",
  color: "black",
  height: "40px",
  textTransform: "capitalize",
  ".MuiButtonBase-root-MuiButton-root": {
    borderColor: "#EFF1F4 !important",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  border: "1.5px solid #EFF1F4",
  borderRadius: "8px",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const generateTableColumns = ({ handleClickRowOption }) => [
  { field: "name", headerName: "BUSINESS NAME", flex: 1 },
  {
    field: "category",
    headerName: "BUSINESS CATEGORY",
    flex: 1,
  },
  { field: "contactEmail", headerName: "EMAIL ADDRESS", flex: 1 },
  {
    field: "createdOn",
    headerName: "DATE & TIME",
    valueFormatter: ({ value }) => {
      return format(new Date(Number(value) * 1000), "dd/MM/yyyy hh:mm a");
    },
    flex: 1,
  },
  {
    field: "",
    headerName: "",
    align: "center",
    renderCell: ({ row }) => {
      return (
        <BusinessOptions
          clicker={(option) => {
            handleClickRowOption(option, row);
          }}
        />
      );
    },
  },
];

export const Businesses = () => {
  const navigate = useNavigate();
  const { businessId } = useParams();
  const { data, isLoading } = useGetBusinessesQuery(businessId);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClickRowOption = (option, row) => {
    if (option === "View business details") {
      navigate(`/businesses/${row.businessId}/transactions`);
    } else if (option === "Generate API key") {
      setSelectedRow(row);
    } else {
    }
  };

  return (
    <Box>
      <Wrapper
        sx={{
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Wrapper>
          <Typography variant="dashboardHeading">Businesses</Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Wrapper>

        <Wrapper>
          <AddBusiness />
          <CsvButton variant="outlined">
            <Wrapper>
              <ExportIcon />
              <div style={{ marginTop: 5 }}> Export csv</div>
            </Wrapper>
          </CsvButton>
        </Wrapper>
      </Wrapper>

      <DataGrid
        rows={data || []}
        loading={isLoading}
        columns={generateTableColumns({ handleClickRowOption })}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[9]}
        checkboxSelection
        disableColumnMenu
        disableRowSelectionOnClick
      />

      <ApiKey
        open={Boolean(selectedRow)}
        handleClose={() => setSelectedRow(null)}
        businessId={selectedRow?.businessId}
      />
    </Box>
  );
};
