import { Box, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import QRCode from "qrcode";
import { useState } from "react";

import { TextInput } from "components/TextInput";
import { formatRequestPayload } from "utils/helpers";
import { useInitiateTwoFaSetup, useVerifyTwoFaSetup } from "api/settings";
import { useAuthContext } from "context/AuthContext";
import { TFA_STATUS } from "constants/tfa";

import { CompleteTwoFaModal } from "./components/CompleteTwoFaModal";
import { DisableTwoFactorAuthentication } from "./components/DisableTwoFa";

export const TwoFactorAuthentication = () => {
  const { userProfile } = useAuthContext();
  const [qrCodeURI, setQrCodeURI] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: initiateTwoFaSetup, isPending: isInitiatingSetup } =
    useInitiateTwoFaSetup();
  const { mutate: completeTwoFaSetup, isPending: isCompletingSetup } =
    useVerifyTwoFaSetup();

  const formik = useFormik({
    initialValues: {
      email: userProfile?.email || "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
      phone: Yup.string().required("Number is required"),
    }),
    onSubmit: (values) => {
      initiateTwoFaSetup(formatRequestPayload(values), {
        onSuccess: (res) => {
          QRCode.toDataURL(res.data.totpcode)
            .then((uri) => {
              setQrCodeURI(uri);
            })
            .catch((error) => {
              enqueueSnackbar("QR code generation failed:", {
                variant: "error",
              });
            });
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      });
    },
  });

  const { handleSubmit } = formik;

  const handleCloseModal = () => {
    setQrCodeURI(null);
  };

  const handleCompleteSetup = (data = {}) => {
    const { otp, tfaMedium } = data;
    completeTwoFaSetup(
      { data: { otp: otp, type: tfaMedium } },
      {
        onSuccess: (res) => {
          handleCloseModal();
          enqueueSnackbar("Two-Factor Authencation Setup Completed", {
            variant: "success",
          });
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      }
    );
  };

  const is2FaEnabled =
    userProfile?.twoFactorAuthStatus === TFA_STATUS.DISABLED ||
    userProfile?.twoFactorAuthStatus === TFA_STATUS.NOTINITIATE ||
    userProfile?.twoFactorAuthStatus === TFA_STATUS.INITIATE;

  return (
    <Box sx={{ width: "500px", paddingX: "50px" }}>
      {is2FaEnabled && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(formik.onSubmit);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flexStart",
            gap: "30px",
          }}
        >
          <TextInput
            label="Email Address"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              Boolean(formik.touched.email) && Boolean(formik.errors.email)
            }
          />
          <TextInput
            label="Phone Number"
            type="string"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              Boolean(formik.touched.phone) && Boolean(formik.errors.phone)
            }
          />

          <Button
            disableRipple
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              margin: "30px 0px",
              textTransform: "capitalize",
            }}
            size="large"
            disabled={isInitiatingSetup}
          >
            {isInitiatingSetup && (
              <CircularProgress size={18} sx={{ mr: 0.5, color: "#fff" }} />
            )}
            Enable two-factor authentication
          </Button>
        </form>
      )}
      {userProfile?.twoFactorAuthStatus === TFA_STATUS.ENABLED && (
        <DisableTwoFactorAuthentication />
      )}
      <CompleteTwoFaModal
        isOpen={Boolean(qrCodeURI)}
        data={qrCodeURI}
        handleClose={handleCloseModal}
        isLoading={isCompletingSetup}
        handleContinue={handleCompleteSetup}
      />
    </Box>
  );
};
