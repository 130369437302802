import { styled } from "@mui/material/styles";
import MUITypography from "@mui/material/Typography";

export const TYPOGRAPHY_VARIANTS = {
  notification: {
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
  },
  emailHeading: {
    fontSize: 22,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  emailSubHeadline: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
  },
  emailBody: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 24,
  },
  emailBodyBold: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 24,
  },
  emailFooter: {
    fontSize: 10,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 18,
  },

  loginHeading: {
    fontSize: 28,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },

  loginLabel: {
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
  loginfooter: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 500,
  },
  dashboardHeading: {
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },
  dashboardBody: {
    fontSize: 13,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  body: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
  },
  alertButton: {
    fontSize: 13.502,
    fontStyle: "normal",
    fontWeight: 500,
  },
  placeHolder: {
    color: "#50555E",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
};

export const Typography = styled(MUITypography)(
  ({ theme, variant = "body" }) => ({
    fontFamily: theme.typography.fontFamily,
    ...TYPOGRAPHY_VARIANTS[variant],
  })
);
