import {
  Button as MuiButton,
  styled,
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  FormControl,
  CircularProgress,
  Box,
} from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { Modal } from "components/Modal";
import { TextInput } from "components/TextInput";
import { useCreateBusinessMutation } from "api/business";
import { formatRequestPayload } from "utils/helpers";
import { Typography } from "components/Typography";

const Button = styled(MuiButton)(() => ({
  textTransform: "capitalize",
  marginRight: 2,
  boxShadow: "none",
  fontWeight: "400",
  "&:hover": { backgroundColor: "#2D75B6", boxShadow: "none" },
}));

const StyledSelect = styled(MuiSelect)(({ theme, error }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${
      error ? theme.palette.error.main : "#EFF1F4"
    } !important`,
  },
}));

const MenuItem = styled(MuiMenuItem)(() => ({
  fontSize: "12px",
}));

export const AddBusiness = () => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: createBusiness, isPending } = useCreateBusinessMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      address: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      category: Yup.string().required(),
      address: Yup.string().required(),
      contactName: Yup.string().required(),
      contactEmail: Yup.string().email().required(),
      contactPhone: Yup.string().max(11).required(),
    }),
    onSubmit: (values, { resetForm }) => {
      createBusiness(formatRequestPayload(values), {
        onSuccess: (res) => {
          enqueueSnackbar(res.message, {
            variant: "success",
          });
          closeBusiness();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      });
    },
  });

  const openBusiness = () => setOpen(true);
  function closeBusiness() {
    setOpen(false);
    formik.resetForm();
  }

  return (
    <>
      <Button disableRipple variant="contained" onClick={openBusiness}>
        Add new business
      </Button>
      <Modal open={open} handleClose={closeBusiness}>
        <Typography
          variant="emailHeading"
          align="center"
          id="modal-modal-title"
          component="h2"
          sx={{ fontSize: "20px", marginBottom: "15px" }}
        >
          Add new Business
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Box display="flex" flexDirection="column" rowGap={2}>
            <TextInput
              label="Business name"
              type="text"
              {...formik.getFieldProps("name")}
              error={
                Boolean(formik.touched.name) && Boolean(formik.errors.name)
              }
            />
            <FormControl fullWidth>
              <Typography variant="loginLabel">Business Category</Typography>
              <StyledSelect
                {...formik.getFieldProps("category")}
                size="small"
                error={
                  Boolean(formik.touched.category) &&
                  Boolean(formik.errors.category)
                }
              >
                <MenuItem value="Real Estate">Real Estate</MenuItem>
                <MenuItem value="Financial Technology">
                  Financial Technology
                </MenuItem>
                <MenuItem value="Agriculture">Agriculture</MenuItem>
                <MenuItem value="Health Care">Health Care</MenuItem>
                <MenuItem value="Education">Education</MenuItem>
                <MenuItem value="Entertainment">Entertainment</MenuItem>
                <MenuItem value="Fashion">Fashion</MenuItem>
                <MenuItem value="Oil and Gas">Oil and Gas</MenuItem>
              </StyledSelect>
            </FormControl>
            <TextInput
              label="Business address"
              type="text"
              {...formik.getFieldProps("address")}
              error={
                Boolean(formik.touched.address) &&
                Boolean(formik.errors.address)
              }
            />
            <TextInput
              label="Contact's name"
              type="text"
              {...formik.getFieldProps("contactName")}
              error={
                Boolean(formik.touched.contactName) &&
                Boolean(formik.errors.contactName)
              }
            />
            <TextInput
              label="Contact's email"
              type="text"
              {...formik.getFieldProps("contactEmail")}
              error={
                Boolean(formik.touched.contactEmail) &&
                Boolean(formik.errors.contactEmail)
              }
            />
            <TextInput
              label="Contact's phone no"
              type="text"
              {...formik.getFieldProps("contactPhone")}
              error={
                Boolean(formik.touched.contactPhone) &&
                Boolean(formik.errors.contactPhone)
              }
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isPending}
              fullWidth
              sx={{ padding: "10px 0px", marginTop: "30px" }}
            >
              Add business
              {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
            </Button>
          </Box>
        </form>
      </Modal>
    </>
  );
};
