import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { getFetch, postFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";
import * as queryKeys from "utils/queryKeys";

const getTeamsRequest = async () => await getFetch(`${baseURL}/teams`);

export const useGetTeamsQuery = () =>
  useQuery({
    queryFn: getTeamsRequest,
    queryKey: [queryKeys.teams],
    select: (data) => data?.data.teams,
  });

const inviteTeamRequest = async (data) =>
  await postFetch(`${baseURL}/teams/invite`, "POST", {}, data);

export const useInviteTeamMutation = (options) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: inviteTeamRequest,
    onSuccess: () => {
      query.invalidateQueries({ queryKey: [queryKeys.teams] });
    },
    ...options,
  });
};

const ResendInviteRequest = async (data) =>
  await postFetch(`${baseURL}/teams/invite/resend`, "POST", {}, data);

export const useResendInviteMutation = (options) => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: ResendInviteRequest,
    onSuccess: () => {
      enqueueSnackbar("invite sent!", {
        variant: "success",
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    ...options,
  });
};
