import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import {
  isBefore,
  isAfter,
  format,
  subDays,
  startOfWeek,
  startOfDay,
} from "date-fns";

import { DatePicker } from "components/DatePicker";
import { useMenu } from "hooks/useMenu";
import { CalendarIcon, ChevronArrowDownIcon } from "components/Icons";
import { Typography } from "components/Typography";
import { Tooltip } from "components/Tooltip";

const MenuItem = styled(MuiMenuItem)(() => ({
  fontSize: "11px",
}));

const dateFieldPropsConfig = {
  openPickerIcon: {
    sx: {
      fontSize: "12px",
    },
  },
  textField: {
    inputProps: {
      sx: {
        fontSize: "10px",
      },
    },
  },
};

const getToolTipMessageForDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return "Start and End Dates are required";
  } else if (isAfter(startDate, new Date())) {
    return "Start Date has to be less than today's date";
  } else if (isBefore(endDate, startDate)) {
    return "End Date cannot come before Start Date";
  } else {
    return "";
  }
};

const isDateRangeButtonDisabled = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return true;
  } else if (isAfter(startDate, new Date())) {
    return true;
  } else if (isBefore(endDate, startDate)) {
    return true;
  } else {
    return false;
  }
};

const LABEL_DATE_FORMAT = "MM/dd/yyyy";

const PREDEFINED_DATE_RANGE = {
  TODAY: "TODAY",
  THIS_WEEK: "THIS_WEEK",
  LAST_30_DAYS: "LAST_30_DAYS",
  LAST_90_DAYS: "LAST_90_DAYS",
};

export const DateRangePicker = ({ handleSelectRange }) => {
  const { anchorEl, handleClose, handleOpen, open } = useMenu();
  const [isCustomDateOpen, setOpenCustomDate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    let timeout;
    if (!open) {
      timeout = setTimeout(() => {
        setOpenCustomDate(false);
      }, 50);
    } else {
      clearTimeout(timeout);
    }
  }, [open]);

  const openCustomDateRange = () => {
    setOpenCustomDate(true);
  };

  const handlePredefinedRanges = (range) => {
    let start;
    let end;
    if (range === PREDEFINED_DATE_RANGE.TODAY) {
      start = startOfDay(new Date());
      end = new Date();
    } else if (range === PREDEFINED_DATE_RANGE.THIS_WEEK) {
      start = startOfWeek(new Date());
      end = new Date();
    } else if (range === PREDEFINED_DATE_RANGE.LAST_30_DAYS) {
      start = subDays(new Date(), 30);
      end = new Date();
    } else if (range === PREDEFINED_DATE_RANGE.LAST_90_DAYS) {
      start = subDays(new Date(), 90);
      end = new Date();
    }
    setStartDate(start);
    setEndDate(end);
    handleSelectRange({ startDate: start, endDate: end });
    handleClose();
  };

  const handleSelectionCustomDate = () => {
    handleSelectRange({ startDate, endDate });
    handleClose();
  };

  const renderButtonLabel = () => {
    if (!startDate || !endDate) {
      return "Select Date";
    }
    return `${format(startDate, LABEL_DATE_FORMAT)} - ${format(
      endDate,
      LABEL_DATE_FORMAT
    )}`;
  };

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        disableRipple
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "8px",
          border: "1px solid #EBEDEF",
          borderRadius: "8px",
          padding: "8px 15px",
        }}
      >
        <CalendarIcon />
        <Typography variant="notification">{renderButtonLabel()}</Typography>
        <ChevronArrowDownIcon
          sx={{ color: "#8E95A4", fontSize: "14px", marginLeft: "5px" }}
        />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            minWidth: anchorEl ? window.getComputedStyle(anchorEl)?.width : 170,
          },
        }}
      >
        {!isCustomDateOpen && [
          <MenuItem
            key={0}
            onClick={() => handlePredefinedRanges(PREDEFINED_DATE_RANGE.TODAY)}
          >
            Today
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() =>
              handlePredefinedRanges(PREDEFINED_DATE_RANGE.THIS_WEEK)
            }
          >
            This week
          </MenuItem>,
          <MenuItem
            key={2}
            onClick={() =>
              handlePredefinedRanges(PREDEFINED_DATE_RANGE.LAST_30_DAYS)
            }
          >
            Last 30 days
          </MenuItem>,
          <MenuItem
            key={3}
            onClick={() =>
              handlePredefinedRanges(PREDEFINED_DATE_RANGE.LAST_90_DAYS)
            }
          >
            Last 90 days
          </MenuItem>,
          <MenuItem key={4} onClick={openCustomDateRange}>
            Custom date
          </MenuItem>,
        ]}
        {isCustomDateOpen && (
          <CustomDateRange
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleConfirmSelection={handleSelectionCustomDate}
          />
        )}
      </Menu>
    </>
  );
};

const CustomDateRange = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleConfirmSelection,
}) => {
  const disableApplyCustomDateButton = isDateRangeButtonDisabled(
    startDate,
    endDate
  );
  return (
    <Box display="flex" justifyContent="center" marginTop={0.5}>
      <Box display="flex" flexDirection="column" rowGap="10px">
        <Box sx={{ width: "150px" }}>
          <DatePicker
            placeholder="Start Date"
            slotProps={dateFieldPropsConfig}
            value={startDate}
            onChange={setStartDate}
            maxDate={new Date()}
          />
        </Box>

        <Box sx={{ width: "150px" }}>
          <DatePicker
            placeholder="End Date"
            slotProps={dateFieldPropsConfig}
            value={endDate}
            onChange={setEndDate}
            minDate={startDate}
            maxDate={new Date()}
          />
        </Box>

        <Tooltip
          arrow
          describeChild={true}
          title={getToolTipMessageForDateRange(startDate, endDate)}
          disableFocusListener={!disableApplyCustomDateButton}
          disableHoverListener={!disableApplyCustomDateButton}
          disableTouchListener={!disableApplyCustomDateButton}
        >
          <Box display="flex" flexDirection="column">
            <Button
              variant="contained"
              sx={{ fontSize: "12px" }}
              disabled={disableApplyCustomDateButton}
              onClick={handleConfirmSelection}
            >
              Apply
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
