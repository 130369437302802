import {
  Button as MuiButton,
  styled,
  Autocomplete,
  TextField,
  CircularProgress,
  FormControl,
  Box,
} from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { Modal } from "components/Modal";
import { TextInput } from "components/TextInput";
import { Typography } from "components/Typography";
import { formatRequestPayload } from "utils/helpers";
import {
  useCreateAccountMutation,
  useGetBankQuery,
  useGetAccountNameMutation,
} from "api/bank";

const Button = styled(MuiButton)(() => ({
  textTransform: "capitalize",
  marginRight: 2,
  boxShadow: "none",
  fontWeight: "400",
  "&:hover": { backgroundColor: "#2D75B6", boxShadow: "none" },
}));

export const AddBankAccount = () => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data: banksList = [] } = useGetBankQuery();
  const { mutate: createAccount, isPending } = useCreateAccountMutation();

  const { mutate: fetchAccountName } = useGetAccountNameMutation({
    onSuccess: (response) => {
      if (
        response?.status === "success" &&
        response.data?.acccount?.accountName
      ) {
        formik.setFieldValue("accountName", response.data.acccount.accountName);
      } else {
        enqueueSnackbar(response.message || "Could not get account name", {
          variant: "error",
        });
        formik.setFieldValue("accountName", "");
      }
    },
    onError: () => {
      enqueueSnackbar("Could not retrieve account name", { variant: "error" });
      formik.setFieldValue("accountName", "");
    },
  });

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      accountName: "",
      bankCode: "",
    },
    validationSchema: Yup.object({
      accountName: Yup.string().required("Couldn't validate account number"),
      accountNumber: Yup.string()
        .required("Account number is required")
        .matches(/^\d{10}$/, "Account number must be 10 digits"),
      bankCode: Yup.string().required("Bank code is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      delete values.accountName;
      createAccount(formatRequestPayload(values), {
        onSuccess: (res) => {
          enqueueSnackbar(res.message, { variant: "success" });
          closeAccount();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      });
    },
  });

  const handleAccountNumberChange = (e) => {
    const accountNumber = e.target.value;
    formik.setFieldValue("accountNumber", accountNumber);

    if (accountNumber.length === 10 && formik.values.bankCode) {
      fetchAccountName({
        data: { accountNumber, bankCode: formik.values.bankCode },
      });
    }
  };

  const openAccount = () => setOpen(true);
  const closeAccount = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <>
      <Button disableRipple variant="contained" onClick={openAccount}>
        Add new Account
      </Button>
      <Modal open={open} handleClose={closeAccount}>
        <Typography
          variant="emailHeading"
          align="center"
          component="h2"
          sx={{ fontSize: "20px", mb: "15px" }}
        >
          Add new Account
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" flexDirection="column" gap="10px">
            <FormControl fullWidth>
              <Typography variant="loginLabel">Bank Name</Typography>
              <Autocomplete
                options={banksList || []}
                getOptionLabel={(option) => option.name || ""}
                value={banksList?.find(
                  (i) => i.bankCode === formik.values.bankCode
                )}
                onChange={(e, value) => {
                  formik.setFieldValue("bankCode", value?.bankCode || "");
                  formik.setFieldValue("accountName", "");
                  formik.setFieldValue("accountNumber", "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={
                      formik.touched.bankCode && Boolean(formik.errors.bankCode)
                    }
                  />
                )}
              />
            </FormControl>

            <TextInput
              label="Account number"
              type="text"
              value={formik.values.accountNumber}
              onChange={handleAccountNumberChange}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              inputProps={{ pattern: "[0-9]*", maxLength: 10 }}
              error={
                Boolean(formik.touched.accountNumber) &&
                Boolean(formik.errors.accountNumber)
              }
              disabled={!formik.values.bankCode}
            />

            <TextInput
              label="Account name"
              type="text"
              value={formik.values.accountName}
              onChange={formik.handleChange}
              disabled
              error={
                Boolean(formik.touched.accountName) &&
                Boolean(formik.errors.accountName)
              }
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            disabled={isPending}
            fullWidth
            sx={{ padding: "10px 0px", mt: "30px" }}
          >
            Add Account
            {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
          </Button>
        </form>
      </Modal>
    </>
  );
};
