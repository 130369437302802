import SvgIcon from "@mui/material/SvgIcon";

export const MenuIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10ZM16.5625 8.125C15.5273 8.125 14.6875 8.96484 14.6875 10C14.6875 11.0352 15.5273 11.875 16.5625 11.875C17.5977 11.875 18.4375 11.0352 18.4375 10C18.4375 8.96484 17.5977 8.125 16.5625 8.125ZM3.4375 8.125C2.40234 8.125 1.5625 8.96484 1.5625 10C1.5625 11.0352 2.40234 11.875 3.4375 11.875C4.47266 11.875 5.3125 11.0352 5.3125 10C5.3125 8.96484 4.47266 8.125 3.4375 8.125Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
