import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  styled,
} from "@mui/material";
import { useState } from "react";

import { KeyboardArrowDown, KeyboardArrowUp } from "components/Icons";

const CollapseClicker = styled(Box)(() => ({
  color: "#1A1C1F",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
}));

const BodyTableRow = styled(TableRow)(({ open }) => ({
  "& .MuiTableCell-root": {
    borderBottom: "none",
  },
}));

export const EXPAND_DETAILS_COLUMN = {
  field: "@More:Details",
  headerName: "",
  renderCell: (row, { open, toggle } = {}) => {
    return (
      <IconButton
        disableRipple={true}
        aria-label="expand row"
        size="small"
        onClick={toggle}
      >
        {open ? (
          <CollapseClicker>
            Collapse
            <KeyboardArrowUp />
          </CollapseClicker>
        ) : (
          <CollapseClicker>
            View Details
            <KeyboardArrowDown />
          </CollapseClicker>
        )}
      </IconButton>
    );
  },
};

const DefaultDetails = () => {
  return <div />;
};

export function CustomTableRow({ columns, row, expandableRowComponent }) {
  const [open, setOpen] = useState(false);
  const DetailsComponent = expandableRowComponent ?? DefaultDetails;
  return (
    <>
      <BodyTableRow hover sx={{ py: 2 }}>
        {columns.map((column, i) => {
          if (column.field === EXPAND_DETAILS_COLUMN.field) {
            return (
              <TableCell>
                {column.renderCell(row, {
                  open,
                  toggle: () => setOpen((prev) => !prev),
                })}
              </TableCell>
            );
          } else {
            const value = row[column.field] ? row[column.field] : null;
            return (
              <TableCell key={column.field} align={column.align}>
                {column.renderCell ? column.renderCell(row, i) : value}
              </TableCell>
            );
          }
        })}
      </BodyTableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 1 }}>
              <DetailsComponent row={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
