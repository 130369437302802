import SvgIcon from "@mui/material/SvgIcon";

export const KeyboardArrowDown = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_775_30462)">
        <path
          d="M12.0007 13.1727L16.9507 8.22266L18.3647 9.63666L12.0007 16.0007L5.63672 9.63666L7.05072 8.22266L12.0007 13.1727Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_775_30462">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
