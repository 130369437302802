import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { useAuthContext } from "context/AuthContext";
import { SIDEBAR_DRAWER_WIDTH, HEADER_HEIGHT } from "constants/layout";

import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar/Sidebar";

const Container = styled(Box)(({ theme }) => ({
  height: "100vh",
  maxHeight: "100vh",
  width: "100vw",
  maxWidth: "100vw",
  overflow: "hidden",
  background: theme.palette.background.default,
}));

const Content = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  marginLeft: SIDEBAR_DRAWER_WIDTH,
  marginTop: HEADER_HEIGHT,
  width: `calc(100% - ${SIDEBAR_DRAWER_WIDTH}px)`,
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
  overflow: "auto",
}));

const Inner = styled(Box)(({ theme }) => ({
  height: "100%",
  minHeight: "100%",
  padding: "30px",
}));

export const Layout = () => {
  const { userProfile } = useAuthContext();

  if (!userProfile) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Header />
      <Sidebar />
      <Content>
        <Inner>
          <Outlet />
        </Inner>
      </Content>
    </Container>
  );
};

const LoadingSpinner = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <img
          src="https://frontendgroupdiag.blob.core.windows.net/websiteresource/tapsite/logo.png"
          alt=""
          width={100}
          height={100}
        />
      </Box>
      <Box style={{ transform: "translateY(-115px)" }}>
        <CircularProgress
          disableShrink
          size={120}
          sx={{
            "& .MuiCircularProgress-circle": {
              strokeWidth: 2.6,
            },
          }}
        />
      </Box>
    </Box>
  );
};
