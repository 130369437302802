import { Box, Button, ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { TextInput } from "components/TextInput";
import { Typography } from "components/Typography";
import { useLogin, useTWoFaLogin } from "api/auth";
import { formatRequestPayload } from "utils/helpers";
import { SETTLEMENT_TOKEN_KEY } from "constants/authCred";
import { useAuthContext } from "context/AuthContext";
import {
  CompleteTwoFaModal,
  CompleteTwoFaModalMode,
} from "pages/Settings/components/TwoFa/components/CompleteTwoFaModal";
import { useRequestTwoFaOtp } from "api/settings";
import { TFA_MEDIUM } from "constants/tfa";

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

export const Login = () => {
  const { login } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loginToken, setLoginToken] = useState("");

  const { mutate: loginRequest, isPending } = useLogin();
  const { mutate: twoFaLogin, isPending: isTwoFaLoading } = useTWoFaLogin();
  const { mutate: requestOtp, isPending: isRequestingOtp } =
    useRequestTwoFaOtp();

  const onCompleteLogin = (response) => {
    login(response.data.account);
    sessionStorage.setItem(SETTLEMENT_TOKEN_KEY, response.data.token);
    enqueueSnackbar("logging into your dashboard", {
      variant: "success",
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    }),
    onSubmit: (values) => {
      loginRequest(formatRequestPayload(values), {
        onSuccess: (res) => {
          if (res.data.loginToken) {
            setLoginToken(res.data.loginToken);
            return;
          }
          onCompleteLogin(res);
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      });
    },
  });

  const handleCloseModal = () => {
    setLoginToken("");
  };

  const handleCompleteTwoFa = (data = {}) => {
    const { otp, tfaMedium } = data;
    twoFaLogin(
      { data: { otp: otp, loginToken, type: tfaMedium } },
      {
        onSuccess: (res) => {
          if (res.status === "error") {
            enqueueSnackbar(res.message, {
              variant: "error",
            });
            return;
          }
          onCompleteLogin(res);
          handleCloseModal();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      }
    );
  };

  const handleRequestOtp = (data = {}) => {
    const { onSuccess } = data;
    requestOtp(
      { data: { loginToken, type: TFA_MEDIUM.Email } },
      {
        onSuccess: () => {
          enqueueSnackbar("OTP has been sent to your email", {
            variant: "success",
          });
          onSuccess?.();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      }
    );
  };

  return (
    <>
      <Container>
        <Box sx={{ width: "344px" }}>
          <Typography variant="loginHeading" component="h2">
            Log in to your account
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(formik.onSubmit);
            }}
          >
            <TextInput
              label="Email Address"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                Boolean(formik.touched.email) && Boolean(formik.errors.email)
              }
            />

            <TextInput
              label="Password"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                Boolean(formik.touched.password) &&
                Boolean(formik.errors.password)
              }
            />

            <Button
              type="submit"
              variant="contained"
              disabled={isPending}
              sx={{
                width: "100%",
                margin: "20px 0px",
                textTransform: "capitalize",
              }}
              size="large"
            >
              Login
              {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
            </Button>
          </form>
          <Box display="flex" justifyContent="center">
            <Typography
              color={"#50555E"}
              variant="loginfooter"
              as={"div"}
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              Forgot your password?
              <ButtonBase
                disableRipple
                LinkComponent={Link}
                to="/forgot-password"
                sx={{
                  marginLeft: 0.5,
                  cursor: "pointer",
                  color: "#23203D",
                  textDecoration: "none",
                }}
              >
                Reset it here
              </ButtonBase>
            </Typography>
          </Box>
        </Box>
      </Container>
      <CompleteTwoFaModal
        isOpen={Boolean(loginToken)}
        mode={CompleteTwoFaModalMode.LOGIN}
        handleClose={handleCloseModal}
        isLoading={isTwoFaLoading}
        handleContinue={handleCompleteTwoFa}
        handleRequestOtp={handleRequestOtp}
        isRequestingOtp={isRequestingOtp}
      />
    </>
  );
};
