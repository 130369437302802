import SvgIcon from "@mui/material/SvgIcon";

export const BackArrowIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 21 21" {...props}>
      <g clipPath="url(#clip0_775_30578)">
        <path
          d="M9.4745 10.5001L13.8057 14.8314L12.5685 16.0686L7 10.5001L12.5685 4.93164L13.8057 6.16889L9.4745 10.5001Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_775_30578">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
