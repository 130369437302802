import SvgIcon from "@mui/material/SvgIcon";

export const SuccessNotificationIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path
        d="M9.00506 16.5043C4.86217 16.5043 1.50391 13.146 1.50391 9.00311C1.50391 4.86022 4.86217 1.50195 9.00506 1.50195C13.1479 1.50195 16.5062 4.86022 16.5062 9.00311C16.5062 13.146 13.1479 16.5043 9.00506 16.5043ZM8.25719 12.0036L13.5605 6.6995L12.4998 5.63884L8.25719 9.88224L6.13512 7.76017L5.07446 8.82083L8.25719 12.0036Z"
        fill="white"
      />
    </SvgIcon>
  );
};
