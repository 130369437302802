import { useState, useEffect } from "react";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Modal,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import OtpInput from "react-otp-input";

import { Typography } from "components/Typography";
import { TFA_MEDIUM } from "constants/tfa";

export const CompleteTwoFaModalMode = {
  SETUP: "SETUP",
  LOGIN: "LOGIN",
};

export const CompleteTwoFaModal = ({
  isOpen,
  handleClose,
  data,
  handleContinue,
  isLoading,
  mode = CompleteTwoFaModalMode.SETUP,
  handleRequestOtp,
  isRequestingOtp,
}) => {
  const [otp, setOtp] = useState("");
  const [tfaMedium, setTfaMedium] = useState(TFA_MEDIUM.AUTHENTICATOR_APP);

  const closeModal = () => {
    handleClose();
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  useEffect(() => {
    if (!isOpen) {
      setOtp("");
      setTfaMedium(TFA_MEDIUM.AUTHENTICATOR_APP);
    }
  }, [isOpen]);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
        }}
      >
        {mode === CompleteTwoFaModalMode.SETUP && (
          <Box>
            <Typography
              variant="h6"
              component="h2"
              sx={{ marginBottom: "20px" }}
            >
              Scan the QR Code below
            </Typography>
            <Typography>
              Use an authenticator app or browser extension to scan.
            </Typography>
            <Box display="flex" justifyContent="center">
              <img src={data} style={{ height: "200px" }} alt="QR Code" />
            </Box>
          </Box>
        )}
        {mode === CompleteTwoFaModalMode.LOGIN && (
          <Box>
            <Typography
              variant="h6"
              component="h2"
              sx={{ marginBottom: "20px" }}
            >
              Enter Verification Code
            </Typography>
          </Box>
        )}

        <RadioGroup
          row
          value={tfaMedium}
          onChange={(e) => {
            setTfaMedium(e.target.value);
          }}
          sx={{ marginTop: "10px" }}
        >
          <FormControlLabel
            value={TFA_MEDIUM.AUTHENTICATOR_APP}
            control={<Radio size="small" />}
            label="Use Authenticator App"
            sx={{
              ".MuiTypography-root": {
                fontSize: "13px",
                fontFamily: "BR Firma, sans-serif",
              },
            }}
          />
          <FormControlLabel
            value={TFA_MEDIUM.Email}
            control={<Radio size="small" />}
            label="Use Email"
            sx={{
              ".MuiTypography-root": {
                fontSize: "13px",
                fontFamily: "BR Firma, sans-serif",
              },
            }}
          />
        </RadioGroup>
        <Box sx={{ display: "flex", marginTop: "20px" }}>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            renderSeparator={<span> </span>}
            renderInput={(props, index) => (
              <input
                {...props}
                style={{
                  width: "40px",
                  height: "40px",
                  fontSize: "16px",
                  textAlign: "center",
                  border: "1px solid #2D75B6",
                  borderRadius: "5px",
                  margin: "10px",
                }}
              />
            )}
          />
        </Box>
        <Button
          sx={{ marginTop: "20px", textTransform: "capitalize" }}
          variant="contained"
          fullWidth
          onClick={() => handleContinue({ otp, tfaMedium })}
          disabled={isRequestingOtp || isLoading || otp.length < 6}
        >
          {isLoading && (
            <CircularProgress size={18} sx={{ mr: 0.5, color: "#fff" }} />
          )}
          Continue
        </Button>
        {mode === CompleteTwoFaModalMode.LOGIN && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            mt={2}
          >
            <Typography sx={{ color: "text.secondary", textAlign: "center" }}>
              OR
            </Typography>
            <ButtonBase
              disableRipple
              type="button"
              variant="text"
              fullWidth
              onClick={() =>
                handleRequestOtp({
                  tfaMedium,
                  onSuccess: () => setTfaMedium(TFA_MEDIUM.Email),
                })
              }
              disabled={isLoading || isRequestingOtp}
              sx={{
                textTransform: "initial",
                color: "#0E60E2",
                "&.Mui-disabled": {
                  opacity: "0.4 !important",
                },
              }}
            >
              Send OTP to Configured Email
              {isRequestingOtp && (
                <CircularProgress size={18} sx={{ ml: 0.5 }} />
              )}
            </ButtonBase>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
