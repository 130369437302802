import { useState, memo } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

import { CalendarIcon } from "components/Icons";
import { TextInput } from "components/TextInput";

function DateComponent({ placeholder, slots, slotProps, ...restProps }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        slots={{
          textField: TextInput,
          openPickerIcon: CalendarIcon,
          ...slots,
        }}
        slotProps={{
          ...slotProps,
          textField: {
            fullWidth: true,
            onClick: () => setIsOpen(true),
            ...slotProps?.textField,
            inputProps: {
              placeholder: placeholder,
              ...slotProps?.textField?.inputProps,
            },
          },
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        {...restProps}
      />
    </LocalizationProvider>
  );
}

export const DatePicker = memo(DateComponent);
