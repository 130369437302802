import { Box, Button, ButtonBase, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { TextInput } from "components/TextInput";
import { Typography } from "components/Typography";
import { useForgotPassword } from "api/forgotPassword";
import { formatRequestPayload } from "utils/helpers";

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: forgotPasswordRequest, isPending } = useForgotPassword();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),
    onSubmit: (values) => {
      forgotPasswordRequest(formatRequestPayload(values), {
        onSuccess: (res) => {
          enqueueSnackbar("otp sent to your emial", {
            variant: "success",
          });

          navigate("/verify-otp", { state: { email: values.email } });
        },

        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      });
    },
  });
  return (
    <Container>
      <Box sx={{ width: "344px" }}>
        <Typography variant="loginHeading">Forgot your password? </Typography>
        <form
          style={{ paddingTop: "10px" }}
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(formik.onSubmit);
          }}
        >
          <Typography variant="dashboardBody">
            Enter your email address, and we'll send you a link to reset your
            password.
          </Typography>
          <TextInput
            label="Email Address"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              Boolean(formik.touched.email) && Boolean(formik.errors.email)
            }
            sx={{ marginTop: "10px" }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={isPending}
            sx={{
              width: "100%",
              margin: "20px 0px",
              textTransform: "capitalize",
            }}
            size="large"
          >
            Continue
            {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
          </Button>
        </form>
        <Box display="flex" justifyContent="center">
          <ButtonBase
            LinkComponent={Link}
            to="/login"
            sty={{
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <Typography
              variant="loginfooter"
              as={"div"}
              sx={{ textAlign: "center", color: "#23203D" }}
            >
              Return to Log in
            </Typography>
          </ButtonBase>
        </Box>
      </Box>
    </Container>
  );
};