import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getFetch, postFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";
import * as queryKeys from "utils/queryKeys";

export const useGetProfileQuery = (options) =>
  useQuery({
    queryFn: () => getFetch(`${baseURL}/settings/profile`),
    queryKey: [queryKeys.Settings],
    select: (data) => data?.data.profile,
    ...options,
  });

export const usePasswordChangeMutation = (options) =>
  useMutation({
    mutationFn: (data) =>
      postFetch(`${baseURL}/settings/profile/password`, "PUT", {}, data),
    ...options,
  });

export const useInitiateTwoFaSetup = (options) =>
  useMutation({
    mutationFn: (data) => postFetch(`${baseURL}/settings/2fa`, "PUT", {}, data),
    ...options,
  });

export const useVerifyTwoFaSetup = (options) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      postFetch(`${baseURL}/settings/2fa/verify`, "POST", {}, data),
    ...options,
    onSuccess: () => {
      query.invalidateQueries({ queryKey: [queryKeys.Settings] });
      options?.onSuccess();
    },
  });
};

export const useRequestTwoFaOtp = (options) =>
  useMutation({
    mutationFn: (data) =>
      postFetch(`${baseURL}/account/request-2fa-otp`, "POST", {}, data),
    ...options,
  });

export const useDisableTwoFa = (options) =>
  useMutation({
    mutationFn: (data) =>
      postFetch(`${baseURL}/settings/2fa`, "DELETE", {}, data),
    ...options,
  });
