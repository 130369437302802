import React from "react";
import { Avatar, Box, Button, Divider, styled } from "@mui/material";

import { CopyIcon } from "components/Icons";
import { getUserInitials } from "utils/helpers";

const Wrapper = styled(Box)(({ theme }) => ({
  fontFamily: "BR Firma",
  fontSize: "13px",
  padding: theme.spacing(2),
}));

const Title = styled("h2")(() => ({
  fontSize: "20px",
  fontFamily: "BR Firma",
  fontWeight: 600,
  textAlign: "center",
  paddingBottom: "10px",
}));

const Container = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 0",
}));

const BoxContainer = styled(Box)(() => ({
  background: "#EBEEEF",
  borderRadius: "7px",
}));

export function SummaryDetails({ handleChangeRole, handlePermission, data }) {
  return (
    <Wrapper>
      <Title>Summary details</Title>
      <BoxContainer>
        <Wrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                background: "#F0E9E9",
                color: "#8B5C5D",
                width: "60px",
                height: "60px",
              }}
            >
              {getUserInitials(data?.fullname)}
            </Avatar>
            <Button
              disableRipple
              type="submit"
              onClick={handleChangeRole}
              sx={{
                border: "1px solid #2D75B6",
                weight: "60px",
                height: "40px",
                fontWeight: 600,
              }}
            >
              Change Role
            </Button>
          </div>
          <p style={{ paddingBottom: "10px" }}>{data.name}</p>
          <Container>
            <p>Email address</p>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <p>{data.email}</p>
              <CopyIcon />
            </div>
          </Container>
          <Divider />
          <Container>
            <p>Last Login</p>
            <p>{data.lastModifiedOn}</p>
          </Container>
          <Divider />
          <Container>
            <p>Role</p>
            <p>{data.roles}</p>
          </Container>
        </Wrapper>
      </BoxContainer>
      <div style={{ paddingTop: "20px" }}>
        <Button
          disableRipple
          type="submit"
          onClick={handlePermission}
          variant="contained"
          sx={{
            width: "100%",
            margin: "15px 0px",
            textTransform: "capitalize",
          }}
          size="large"
        >
          View Permission
        </Button>
        <Button
          disableRipple
          type="submit"
          variant="contained"
          sx={{
            width: "100%",
            margin: "10px 0px",
            textTransform: "capitalize",
            background: "#FC6250",
            "&:hover": {
              backgroundColor: "#FC6250",
              boxShadow: "none",
            },
          }}
          size="large"
        >
          Remove Member
        </Button>
      </div>
    </Wrapper>
  );
}
