import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

import { TYPOGRAPHY_VARIANTS } from "components/Typography";
import {
  SuccessNotificationIcon,
  ErrorNotificationIcon,
} from "components/Icons";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.success.main,
      fontFamily: theme.typography.fontFamily,
      ...TYPOGRAPHY_VARIANTS.notification,
      fontSize: "13.5px",
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.error.main,
      fontFamily: theme.typography.fontFamily,
      ...TYPOGRAPHY_VARIANTS.notification,
      fontSize: "13.5px",
    },
  })
);

const SuccessIcon = () => {
  return (
    <IconButton
      sx={{
        backgroundColor: "#349773",
        marginRight: "5px",
      }}
      disableRipple
    >
      <SuccessNotificationIcon />
    </IconButton>
  );
};

const ErrorIcon = () => {
  return (
    <IconButton
      sx={{
        backgroundColor: "#C83939",
        marginRight: "5px",
      }}
      disableRipple
    >
      <ErrorNotificationIcon />
    </IconButton>
  );
};

export function NotificationProvider({ children }) {
  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
      iconVariant={{
        success: <SuccessIcon />,
        error: <ErrorIcon />,
      }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {children}
    </SnackbarProvider>
  );
}
