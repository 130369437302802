import * as React from "react";
import { format } from "date-fns";

import { useGetActivity } from "api/activity";
import { DataGrid } from "components/DataGrid";

const columns = [
  {
    field: "createdBy",
    headerName: "Full Name",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Action Details",
    flex: 1,
  },
  {
    field: "createdOn",
    headerName: "Date & Time",
    flex: 1,
    valueFormatter: ({ value }) =>
      format(new Date(Number(value) * 1000), "dd/MM/yyyy hh:mm a"),
  },
];

export function ActivitiesDataTable() {
  const { data = [], isLoading } = useGetActivity();

  return (
    <DataGrid
      rows={(data || []).filter((item) => item.id)}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.id}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      pageSizeOptions={[10, 25, 50]}
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
}
