import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFetch, postFetch } from "config/fetch";
import { baseURL } from "constants/baseUrl";
import * as queryKeys from "utils/queryKeys";
const getBusinessesRequest = async () =>
  await getFetch(`${baseURL}/businesses`);

export const useGetBusinessesQuery = () =>
  useQuery({
    queryFn: getBusinessesRequest,
    queryKey: [queryKeys.Businesses],
    select: (data) => data?.data.businesses,
  });

const createBusinessRequest = async (data) =>
  await postFetch(`${baseURL}/businesses`, "POST", {}, data);

export const useCreateBusinessMutation = (options) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: createBusinessRequest,
    onSuccess: () => {
      query.invalidateQueries({ queryKey: [queryKeys.Businesses] });
    },
    ...options,
  });
};

const getBusinessApiKeyRequest = async (businessId) =>
  await getFetch(`${baseURL}/businesses/${businessId}/api-key`);

export const useGetBusinesseApiKeyQuery = (businessId) =>
  useQuery({
    queryFn: () => getBusinessApiKeyRequest(businessId),
    queryKey: [queryKeys.BusinessesApiKey, { businessId }],
    select: (data) => data?.data.apiKey,
    enabled: !!businessId,
  });

const resetBusinessApiKeyRequest = async (businessId, data) =>
  await postFetch(
    `${baseURL}/businesses/${businessId}/api-key/reset`,
    "POST",
    {},
    data
  );

export const useResetBusinessApiMutation = (options) => {
  const query = useQueryClient();
  return useMutation({
    mutationFn: ({ businessId, data }) =>
      resetBusinessApiKeyRequest(businessId, data),
    onSuccess: (_, { businessId }) => {
      query.invalidateQueries({
        queryKey: [queryKeys.BusinessesApiKey, { businessId }],
      });
    },
    ...options,
  });
};

const getBusinessDetails = async (businessId) => {
  return await getFetch(
    `${baseURL}/businesses/${businessId}/transactions`,
    "GET",
    {}
  );
};

export const useGetBusinessDetails = (businessId) =>
  useQuery({
    queryFn: () => getBusinessDetails(businessId),
    queryKey: [queryKeys.BusinessDetails, { businessId }],
    select: (data) => data?.data?.transactions,
  });
