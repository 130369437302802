import React from "react";
import { Box, Skeleton, styled } from "@mui/material";

const BoxContainer = styled(Box)(() => ({
  gap: "40px",
  justifyContent: "space-between",
  display: "flex",
  paddingTop: "24px",
}));

export function DashboardLoadingSkeleton() {
  return (
    <Box>
      <BoxContainer>
        <Skeleton
          sx={{ flex: 1, borderRadius: "10px" }}
          variant="rectangular"
          height={100}
        />
        <Skeleton
          sx={{ flex: 1, borderRadius: "10px" }}
          variant="rectangular"
          height={100}
        />
        <Skeleton
          sx={{ flex: 1, borderRadius: "10px" }}
          variant="rectangular"
          height={100}
        />
        <Skeleton
          sx={{ flex: 1, borderRadius: "10px" }}
          variant="rectangular"
          height={100}
        />
      </BoxContainer>
      <Box sx={{ paddingTop: "40px" }}>
        <Skeleton
          variant="rectangular"
          sx={{ borderRadius: "10px" }}
          height={300}
        />
      </Box>
    </Box>
  );
}
