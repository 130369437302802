import SvgIcon from "@mui/material/SvgIcon";

export const ResendIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
        <path d="M8.0007 15.1671C4.53404 15.1671 1.7207 12.3471 1.7207 8.88708C1.7207 7.64042 2.08737 6.43375 2.7807 5.39375C2.93404 5.16708 3.24737 5.10042 3.47404 5.25375C3.7007 5.40708 3.76737 5.72042 3.61404 5.94708C3.03404 6.81375 2.72737 7.83375 2.72737 8.88042C2.72737 11.7937 5.09404 14.1604 8.00737 14.1604C10.9207 14.1604 13.2874 11.7937 13.2874 8.88042C13.2874 5.96708 10.914 3.60042 8.0007 3.60042C7.38737 3.60042 6.78737 3.68708 6.2207 3.86042C5.95404 3.94042 5.67404 3.79375 5.59404 3.52708C5.51404 3.26042 5.6607 2.98042 5.92737 2.90042C6.59404 2.70042 7.28737 2.59375 8.0007 2.59375C11.4674 2.59375 14.2807 5.41375 14.2807 8.87375C14.2807 12.3338 11.4674 15.1671 8.0007 15.1671Z" fill="currentColor"/>
        <path d="M5.24625 4.0461C5.13292 4.0461 5.01292 4.0061 4.91959 3.9261C4.70626 3.73943 4.68626 3.4261 4.86626 3.21943L6.79292 1.0061C6.97292 0.799429 7.29292 0.772763 7.49959 0.959429C7.70626 1.13943 7.72625 1.45943 7.54625 1.6661L5.61959 3.87276C5.51959 3.9861 5.37959 4.0461 5.24625 4.0461Z" fill=""/>currentColor
        <path d="M7.49241 5.6861C7.39241 5.6861 7.28574 5.65277 7.19907 5.59277L4.94574 3.9461C4.72574 3.7861 4.67907 3.47277 4.83907 3.25277C4.99907 3.0261 5.31241 2.97943 5.53907 3.13943L7.78574 4.77943C8.00574 4.93943 8.05907 5.25277 7.89241 5.47943C7.79907 5.61943 7.64574 5.6861 7.49241 5.6861Z" fill="currentColor"/>

    </SvgIcon>
  );
};