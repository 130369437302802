import {
  DashboardIcon,
  TransactionsIcon,
  AdministrationIcon,
  BusinessesIcon,
  ActivityLogIcon,
  SettingsIcon,
} from "components/Icons";

export const navList = {
  sectionOne: [
    {
      title: "Dashboard",
      to: "/",
      icon: DashboardIcon,
      children: null,
    },
    {
      title: "Transactions",
      to: "/transactions",
      icon: TransactionsIcon,
      children: null,
    },
    {
      title: "Businesses",
      to: "/businesses",
      icon: BusinessesIcon,
      children: null,
    },
    {
      title: "Banks",
      to: "/banks",
      icon: BusinessesIcon,
      children: null,
    },
    {
      title: "Administration",
      to: "/administration",
      icon: AdministrationIcon,
      submenu: [
        {
          title: "Team mates",
          to: "/administration/team-mate",
        },
        {
          title: "Role priviledges",
          to: "/administration/role-privileges",
        },
      ],
    },
    {
      title: "Activity Log",
      to: "/activity-log",
      icon: ActivityLogIcon,
      submenu: null,
    },
  ],
  sectionTwo: [
    {
      title: "Settings",
      to: "/settings",
      icon: SettingsIcon,
      children: null,
    },
  ],
};
