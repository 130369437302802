import { Box, TableContainer, styled } from "@mui/material";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import React from "react";

import {
  CustomTable,
  EXPAND_DETAILS_COLUMN,
} from "components/CustomTable/CustomTable";
import { TableLoadingSkeleton } from "components/DataGrid/components/TableLoadingSkeleton";
import { useGetTransactionDetailsQuery } from "api/transactions";

const columns = [
  { field: "description", headerName: "DESCRIPTION" },
  {
    field: "amount",
    headerName: "AMOUNT",
  },
  {
    field: "createdOn",
    headerName: "DATE & TIME",
    renderCell: (row) => {
      return format(new Date(row.createdOn * 1000), "dd/MM/yyyy hh:mm a");
    },
  },
  {
    field: "status",
    headerName: "STATUS",
    renderCell: (row) => {
      if (row.status === "1") {
        return (
          <StatusStyle
            sx={{
              backgroundColor: "#E9F8F3",
              color: "#25B883",
              width: "70%",
            }}
          >
            Successful
          </StatusStyle>
        );
      } else {
        return (
          <StatusStyle
            sx={{
              backgroundColor: "#FEEFF0",
              color: "#F25A68",
              width: "50%",
            }}
          >
            Failed
          </StatusStyle>
        );
      }
    },
  },
  {
    ...EXPAND_DETAILS_COLUMN,
  },
];

const detailsColumns = [
  { field: "transIdRef", headerName: "REFERENCE NUMBER" },
  {
    field: "bankName",
    headerName: "BANK NAME",
  },
  {
    field: "paymentMode",
    headerName: "PAYMENT MODE",
  },
  {
    field: "accountNo",
    headerName: "BANK ACCOUNT NO",
  },
  {
    field: "accountName",
    headerName: "ACCOUNT NAME",
  },
];

const StatusStyle = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  borderRadius: "20px",
  padding: "5px 2px",
}));

const StyledCustomTable = styled(CustomTable)(() => ({
  ".MuiTableRow-head .MuiTableCell-root": {
    borderBottom: "none",
    paddingLeft: "0px",
    paddingRight: "0px",
    color: "#7C7A78",
  },
  ".MuiTableRow-root .MuiTableCell-root": {
    borderBottom: "none",
    paddingLeft: "0px",
  },
}));

export const BusinessTransactionWithdrawalDetailsTable = () => {
  const { transactionId } = useParams();
  const { data, isLoading } = useGetTransactionDetailsQuery(transactionId);

  if (isLoading) {
    return (
      <Box height={300} overflow="hidden">
        <TableLoadingSkeleton />
      </Box>
    );
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <CustomTable
        columns={columns}
        getRowId={(row) => row.transDId}
        rows={data || []}
        expandableRowComponent={({ row }) => {
          return (
            <StyledCustomTable
              columns={detailsColumns}
              rows={[row]}
              size="small"
            />
          );
        }}
      />
    </TableContainer>
  );
};
