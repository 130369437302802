import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useState } from "react";

import { MenuIcon } from "components/Icons";

const moreOptions = ["More Details"];

export const TeamOption = ({ handleMenuClick, data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (option) => {
    handleMenuClick(option);
    handleClose();
  };

  if (data.invite === "PENDING") {
    return <p></p>;
  } else {
    return (
      <section>
        <Button onClick={handleClick}>
          <MenuIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {moreOptions.map((option) => (
            <MenuItem
              sx={{ fontSize: "13px" }}
              key={option}
              selected={option === "Pyxis"}
              onClick={() => handleItemClick(option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </section>
    );
  }
};
