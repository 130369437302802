import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export function CustomPermission({
  selectedPermissions,
  permissions,
  handleChange,
}) {
  const handleCheckboxChange = (permissionId) => {
    const permission = selectedPermissions.find((id) => id === permissionId);
    let newPermissions = [];
    if (permission) {
      newPermissions = selectedPermissions.filter((id) => id !== permissionId);
    } else {
      newPermissions = [...selectedPermissions, permissionId];
    }
    handleChange(newPermissions);
  };

  return (
    <List>
      {permissions.map((permission, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={Boolean(
                selectedPermissions.find((id) => id === permission.id)
              )}
              onChange={() => handleCheckboxChange(permission.id)}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={permission.action} />
        </ListItem>
      ))}
    </List>
  );
}
