import React from "react";
import styled from "@emotion/styled";
import { Box, InputBase } from "@mui/material";

import { SearchIcon } from "components/Icons";
import { Typography } from "components/Typography";
import { CreateCustomRole } from "./component/CreateCustomRole";
import { CardContainer } from "./component/CardContainer";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  paddingLeft: "7px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  border: "1.5px solid #EFF1F4",
  borderRadius: "8px",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export const Roles = () => {
  return (
    <>
      <Wrapper
        sx={{
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Wrapper>
          <Typography variant="dashboardHeading">
            Roles and permission
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Wrapper>

        <CreateCustomRole />
      </Wrapper>
      <Box >
        <CardContainer />
      </Box>
    </>
  );
};
