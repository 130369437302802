import React from "react";
import { useState } from "react";
import {
  Box,
  Button as MuiButton,
  FormControl,
  InputBase,
  styled,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";

import { Typography } from "components/Typography";
import {
  ActivityIcon,
  CalendarIcon,
  ExportIcon,
  SearchIcon,
} from "components/Icons";
import { ActivitiesDataTable } from "./component/ActivityDataTable";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  paddingLeft: "7px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  border: "1.5px solid #EFF1F4",
  borderRadius: "8px",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));
const Select = styled(MuiSelect)(() => ({
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #EFF1F4 !important",
  },
}));

const Button = styled(MuiButton)(() => ({
  borderColor: "#EFF1F4",
  color: "black",
  height: "40px",
  textTransform: "capitalize",
  ".MuiButtonBase-root-MuiButton-root": {
    borderColor: "#EFF1F4 !important",
  },
}));

export const Activity = () => {
  const [date, setDate] = useState("");
  const [activityType, setActivityType] = useState("");

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  const handleActivityChange = (event) => {
    setActivityType(event.target.value);
  };
  return (
    <Box>
      <Wrapper
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Wrapper>
          <Typography variant="dashboardHeading">Activity Log</Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Wrapper>
        <Wrapper>
          <FormControl
            sx={{
              m: 1,
              minWidth: 120,
            }}
            size="small"
          >
            <Select
              onChange={handleActivityChange}
              value={activityType}
              displayEmpty
            >
              <MenuItem value="" disabled>
                <Wrapper>
                  <ActivityIcon />
                  <Typography sx={{ margin: "2px 0px 0px 5px" }} variant="body">
                    Activities
                  </Typography>
                </Wrapper>
              </MenuItem>
              <MenuItem value={1}>All</MenuItem>
              <MenuItem value={2}>Staff Login</MenuItem>
              <MenuItem value={3}>Password Change</MenuItem>
              <MenuItem value={4}>Create Role</MenuItem>
              <MenuItem value={5}>Generate Api</MenuItem>
              <MenuItem value={6}>Regenerate Api key</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              m: 1,
              minWidth: 120,
            }}
            size="small"
          >
            <Select
              onChange={handleDateChange}
              value={date}
              displayEmpty
              sx={{ fontSize: "14px" }}
            >
              <MenuItem value="" disabled>
                <Wrapper>
                  <CalendarIcon />
                  <Typography sx={{ margin: "2px 0px 0px 5px" }} variant="body">
                    Select date
                  </Typography>
                </Wrapper>
              </MenuItem>

              <MenuItem value={1}>Today</MenuItem>
              <MenuItem value={2}>This week</MenuItem>
              <MenuItem value={3}>Last 30 days</MenuItem>
              <MenuItem value={4}>Last 90 days</MenuItem>
              <MenuItem value={5}>Custom date</MenuItem>
            </Select>
          </FormControl>
          <Button variant="outlined">
            <Wrapper>
              <ExportIcon />
              <Typography sx={{ margin: "2px 0px 0px 5px" }} variant="body">
                Export csv
              </Typography>
            </Wrapper>
          </Button>
        </Wrapper>
      </Wrapper>
      <ActivitiesDataTable />
    </Box>
  );
};
