import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  styled,
  FormControl,
  TextareaAutosize,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { Modal } from "components/Modal";
import { TextInput } from "components/TextInput";
import { Typography } from "components/Typography";
import { CustomPermission } from "./CustomPermission";
import { useCreateRoleMutation, useGetPermission } from "api/roles";
import { formatRequestPayload } from "utils/helpers";
import { fontFamily } from "config/theme";

const Wrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const HelperText = styled(Typography)(() => ({
  fontSize: "12px",
  color: "red",
  paddingLeft: "10px",
}));

const TextArea = styled(TextareaAutosize)(() => ({
  fontSize: "13px",
  fontStyle: "normal",
  fontFamily: fontFamily,
  fontWeight: 600,
  border: "1px solid #F1F0F5",
  "&:focus-visible, &:hover": {
    border: "1px solid #F1F0F5 !important",
    outline: "1px solid #F1F0F5 !important",
  },
}));

const ModalContainer = styled(Box)(() => ({
  height: "80vh",
  overflowY: "auto",
  margin: "0 10px",
  padding: "30px",
  boxSizing: "border-box",
}));
export const CreateCustomRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openSendInvite, setOpenSendInvite] = useState(
    Boolean(location.state?.createRole)
  );
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: createRole, isPending } = useCreateRoleMutation();
  const { data = [] } = useGetPermission();

  useEffect(() => {
    if (location.state?.createRole) {
      navigate(location.path, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      permissionId: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Role description is required"),
      permissionId: Yup.array()
        .of(Yup.string())
        .min(1, "Select at least one permission")
        .required("Select at least one permission"),
    }),
    onSubmit: (values) => {
      createRole(formatRequestPayload(values), {
        onSuccess: () => {
          enqueueSnackbar("Role Successfully Created !", {
            variant: "success",
          });
          handleCloseModal();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
        },
      });
    },
  });

  const handleCloseModal = () => {
    setOpenSendInvite(false);
    formik.resetForm({});
  };

  const permissionLists = data?.map((permissions) => {
    return {
      id: permissions.permissionId,
      action: permissions.description,
    };
  });

  return (
    <Wrapper>
      <Button
        disableRipple
        sx={{
          textTransform: "capitalize",
          padding: "12px 24px",
          boxShadow: "none",
          fontWeight: "400",
          "&:hover": { backgroundColor: "#2D75B6", boxShadow: "none" },
        }}
        variant="contained"
        onClick={() => setOpenSendInvite(true)}
      >
        Create Custom Role
      </Button>

      <Modal
        open={openSendInvite}
        handleClose={handleCloseModal}
        containerStyle={{ paddingLeft: 0, paddingRight: 0, width: "450px" }}
      >
        <ModalContainer>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <FormControl sx={{ width: "100%", paddingBottom: "10px" }}>
              <Typography
                as="h2"
                sx={{ fontSize: "20px", fontWeight: 600, textAlign: "center" }}
              >
                Create Role
              </Typography>
              <TextInput
                label="Name"
                type="text"
                placeholder="Enter role name"
                {...formik.getFieldProps("name")}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
              <Typography variant="loginLabel">Role Description:</Typography>
              <TextArea
                cols={30}
                label="Role description"
                minRows={6}
                placeholder="Enter role description"
                {...formik.getFieldProps("description")}
                sx={{
                  padding: "10px",
                  "&::placeholder": {
                    color: "#BABABA",
                    fontWeight: 600,
                  },
                  ...(formik.touched.description &&
                    Boolean(formik.errors.description) && {
                      borderColor: "error.main",
                    }),
                }}
              />
            </FormControl>

            <FormControl sx={{ width: "100%", py: "10px" }}>
              <Typography sx={{ color: "#BABABA", font: "12px" }}>
                Permission
              </Typography>
              <CustomPermission
                selectedPermissions={formik.values.permissionId}
                permissions={permissionLists}
                handleChange={(selectedPermissions) =>
                  formik.setFieldValue("permissionId", selectedPermissions)
                }
              />
              {formik.touched.permissionId && formik.errors.permissionId && (
                <HelperText>{formik.errors.permissionId}</HelperText>
              )}
            </FormControl>
            <Button
              disableRipple
              type="submit"
              disabled={isPending}
              variant="contained"
              sx={{
                width: "100%",
                textTransform: "capitalize",
              }}
              size="large"
            >
              Create Role
              {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
            </Button>
          </form>
        </ModalContainer>
      </Modal>
    </Wrapper>
  );
};
