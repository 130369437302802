import { Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

import { TextInput } from "components/TextInput";
import { Typography } from "components/Typography";
import { useResetPassword } from "api/forgotPassword";
import { formatRequestPayload } from "utils/helpers";

const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();

  const { mutate: resetPasswordRequest, isPending } = useResetPassword();
  const formik = useFormik({
    initialValues: {
      newPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required(),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Password does not match")
        .required(),
    }),

    onSubmit: (values) => {
      delete values.confirmPassword;
      resetPasswordRequest(
        formatRequestPayload({
          otpToken: location?.state?.otpToken,
          ...values,
        }),
        {
          onSuccess: (res) => {
            enqueueSnackbar("Password Reset Successful", {
              variant: "success",
            });
            navigate("/login", { replace: true });
          },
          onError: (error) => {
            enqueueSnackbar(error.message, { variant: "error" });
          },
        }
      );
    },
  });

  if (!location.state?.otpToken) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Container>
      <Box sx={{ width: "344px" }}>
        <Typography variant="loginHeading" component="h2">
          Set a new password
        </Typography>
        <Typography variant="dashboardBody">
          Enter a new password to access the settlement portal
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          style={{ marginTop: "10px" }}
        >
          <TextInput
            label="New Password"
            type="password"
            {...formik.getFieldProps("newPassword")}
            error={
              Boolean(formik.touched.newPassword) &&
              Boolean(formik.errors.newPassword)
            }
          />

          <TextInput
            label="Confirm Password"
            type="password"
            {...formik.getFieldProps("confirmPassword")}
            error={
              Boolean(formik.touched.confirmPassword) &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />

          <Button
            type="submit"
            variant="contained"
            disabled={isPending}
            disableRipple
            sx={{
              width: "100%",
              margin: "20px 0px",
              textTransform: "capitalize",
            }}
            size="large"
          >
            Change Password
            {isPending && <CircularProgress size={18} sx={{ ml: 0.5 }} />}
          </Button>
        </form>
      </Box>
    </Container>
  );
};
