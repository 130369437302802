const { Box, Skeleton } = require("@mui/material");

export const TableLoadingSkeleton = () => (
  <Box
    sx={{
      height: "max-content",
    }}
  >
    {[...Array(4)].map((_, index) => (
      <Box key={index} sx={{ display: "flex" }}>
        {[...Array(6)].map((_, id) => (
          <Skeleton
            key={index + id}
            variant="rectangular"
            sx={{ my: 2, mx: 1, borderRadius: "5px", flex: 1 }}
          />
        ))}
      </Box>
    ))}
  </Box>
);
