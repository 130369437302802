import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { MenuIcon } from "components/Icons";

export const TransactionMenuOption = ({ transactionId }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = () => {
    navigate(`/transactions/${transactionId}`);
    handleClose();
  };

  return (
    <div>
      <MenuIcon onClick={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleItemClick} sx={{ fontSize: "13px" }}>
          More Details
        </MenuItem>
      </Menu>
    </div>
  );
};
